import React, {useEffect, useRef, useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Paginator} from "primereact/paginator";
import {Messages} from "primereact/messages";
import {UserService} from "../../controllers/UserService";
import {DefaultErrorMessage, MaximumRecordsPerPage} from "../../common/SystemConstants";
import {ReplaceWithUnderscore, ThousandsSeparatorNoCurrency} from "../../common/Utils";
import {showServerError} from "../../common/MessageDialog";
import {SearchFilter} from "../../common/SearchFilter";
import { PaymentService } from '../../controllers/PaymentService';


const ViewPayments = () => {
    let emptyRecord = {
        id: null,
        question: '',
        answer: ''
    };

    const [records, setRecords] = useState(null);
    const [recordDialog, setRecordDialog] = useState(false);
    const [deleteRecordDialog, setDeleteRecordDialog] = useState(false);
    const [record, setRecord] = useState(emptyRecord);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const message = useRef();
    const [searchValue, setSearchValue] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(MaximumRecordsPerPage);
    const [id, setId] = useState(null);
    const [answer, setAnswer] = useState(null);
    const [question, setQuestion] = useState(null);

    const fetchRecordsFromServer = () => {
        setIsLoading(true);
        new PaymentService().getRecords(searchValue, first, limit)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        console.log("Records from server ", value.records);
                        setRecords(value.records);
                        setTotalItems(value.totalItems);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
            console.log(error);
        });
    };

    const onSubmitFilter = () => {
        setSearchValue(searchValue);
        fetchRecordsFromServer();
    };

    const resetFilters = () => {
        setIsLoading(true);
        setSearchValue("");
        fetchRecordsFromServer();
    };

    useEffect(() => {
        fetchRecordsFromServer();
    }, []);

    const onPageChange = (e) => {
        let offset = e.page * MaximumRecordsPerPage;
        setFirst(e.first);
        setLimit(MaximumRecordsPerPage);
        fetchRecordsFromServer();
    };

    const rowIndexTemplate = (rowData, props) => {
        let index = first + parseInt(props.rowIndex + 1, 10);
        return (
            <React.Fragment>
                <span>{index}</span>
            </React.Fragment>
        );
    };
    const openNew = () => {
        setRecord(emptyRecord);
        setSubmitted(false);
        setRecordDialog(true);
    };


    const accountBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Account Name</span>
                {rowData.accountName}
            </>
        );
    };

    const amountBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Amount</span>
                {ThousandsSeparatorNoCurrency(rowData.amount)}
            </>
        );
    };

    const referenceIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Reference Id</span>
                {rowData.referenceId}
            </>
        );
    };

    const narrativeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Narrative</span>
                {rowData.narrative}
            </>
        );
    };

    const errorMessageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Error Message</span>
                {rowData.errorMessage}
            </>
        );
    };

    const transactionStatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {ReplaceWithUnderscore(rowData.transactionStatus)}
            </>
        );
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Messages ref={message}/>

                    <div className=" col-12 d-flex justify-content-sm-start justify-content-md-end justify-content-lg-end justify-content-xl-end flex-wrap">
                        <div className="mr-auto p-2">
                            <h5 className="p-m-0">Manage Payment Transactions</h5>
                        </div>
                        <SearchFilter
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            showInputSearchBar={true}
                            showAddNew={false}
                            showResetFilter={false}
                            onSubmitFilter={onSubmitFilter}
                            resetFilters={resetFilters}
                            onAddNew={openNew}
                        />
                    </div>

                    <hr/>

                    <DataTable
                        ref={dt}
                        value={records}
                        selectionMode="single" selection={selectedRecord}
                        onSelectionChange={(e) => setSelectedRecord(e.value)}
                        rows={20}
                        rowsPerPageOptions={[20, 50, 100]}
                        globalFilter={globalFilter}
                        dataKey="id"
                        paginator={false}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        paginatorPosition="bottom"
                        paginatorAlwaysVisible="false"
                        emptyMessage="No record found."
                        loading={isLoading}
                        reflow="true"
                        responsiveLayout="scroll">
                        <Column field="Index" header="#" style={{width: "6rem"}} body={rowIndexTemplate}></Column>
                        <Column field="fullName" header="Account Name" body={accountBodyTemplate} headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                        <Column field="amount" header="Amount" body={amountBodyTemplate} headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                        <Column field="referenceId" header="Reference Id" body={referenceIdBodyTemplate} headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                        <Column field="transactionStatus" header="Status" body={transactionStatusBodyTemplate} headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                        <Column field="narrative" header="Narrative" body={narrativeBodyTemplate} headerStyle={{width: '25%', minWidth: '10rem'}}></Column>
                        <Column field="errorMessage" header="Error Message" body={errorMessageBodyTemplate}></Column>
                    </DataTable>
                    <Paginator first={first} rows={MaximumRecordsPerPage} totalRecords={totalItems} alwaysShow={false} onPageChange={onPageChange}/>
                </div>
            </div>
        </div>
    );
};

export default ViewPayments;
