import React, {useEffect} from 'react';

const Dashboard = () => {

    useEffect(() => {

    }, []);

    return (
        <div className="layout-dashboard">
            <div className="grid">
                {/*<div className="col-12 lg:col-6 xl:col-3">
                    <div className="overview-box sales">
                        <i className="overview-icon pi pi-dollar"></i>
                        <span className="overview-title">Sales</span>
                        <i className="overview-arrow pi pi-chevron-circle-up"></i>
                        <div className="overview-numbers">$ 92,440</div>
                        <div className="overview-subinfo">21% more than yesterday</div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="overview-box views">
                        <i className="overview-icon pi pi-search"></i>
                        <span className="overview-title">Views</span>
                        <i className="overview-arrow pi pi-chevron-circle-up"></i>
                        <div className="overview-numbers">7029</div>
                        <div className="overview-subinfo">2% more than yesterday</div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="overview-box users">
                        <i className="overview-icon pi pi-users"></i>
                        <span className="overview-title">Users</span>
                        <i className="overview-arrow pi pi-chevron-circle-up"></i>
                        <div className="overview-numbers">9522</div>
                        <div className="overview-subinfo">7% more than yesterday</div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="overview-box checkin">
                        <i className="overview-icon pi pi-map-marker"></i>
                        <span className="overview-title">Check-Ins</span>
                        <i className="overview-arrow pi pi-chevron-circle-up"></i>
                        <div className="overview-numbers">4211</div>
                        <div className="overview-subinfo">18% more than yesterday</div>
                    </div>
                </div>*/}

                <div className="col-12 lg:col-8">
                </div>

                <div className="col-12 lg:col-4">
                </div>

                <div className="col-12 md:col-12 lg:col-4">
                </div>

            </div>
        </div>
    );
};

export default Dashboard;
