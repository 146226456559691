import React, { useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FeatureService } from "../../controllers/FeatureService";
import { DefaultErrorMessage, MaximumRecordsPerPage } from "../../common/SystemConstants";
import { showServerError } from "../../common/MessageDialog";
import { Paginator } from "primereact/paginator";
import { SearchFilter } from "../../common/SearchFilter";
import { Messages } from "primereact/messages";
import { reloadCurrentPage } from "../../common/Utils";


const ViewFeatures = () => {
    let emptyRecord = {
        id: null,
        name: '',
        description: ''
    };

    const [records, setRecords] = useState(null);
    const [recordDialog, setRecordDialog] = useState(false);
    const [deleteRecordDialog, setDeleteRecordDialog] = useState(false);
    const [record, setRecord] = useState(emptyRecord);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const message = useRef();
    const [searchValue, setSearchValue] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(MaximumRecordsPerPage);
    const [id, setId] = useState(null);
    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [position, setPosition] = useState(0);

    const fetchRecordsFromServer = () => {
        setIsLoading(true);
        new FeatureService().getRecords(searchValue, first, limit)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setRecords(value.records);
                        setTotalItems(value.totalItems);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    };

    const onSubmitFilter = () => {
        setSearchValue(searchValue);
        fetchRecordsFromServer();
    };

    const resetFilters = () => {
        setIsLoading(true);
        setSearchValue("");
        fetchRecordsFromServer();
    };

    useEffect(() => {
        fetchRecordsFromServer();
    }, []);

    const onPageChange = (e) => {
        let offset = e.page * MaximumRecordsPerPage;
        setFirst(e.first);
        setLimit(MaximumRecordsPerPage);
        fetchRecordsFromServer();
    };

    const rowIndexTemplate = (rowData, props) => {
        let index = first + parseInt(props.rowIndex + 1, 10);
        return (
            <React.Fragment>
                <span>{index}</span>
            </React.Fragment>
        );
    };
    const openNew = () => {
        setRecord(emptyRecord);
        setSubmitted(false);
        setRecordDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setRecordDialog(false);
    };

    const hideDeleteRecordDialog = () => {
        setDeleteRecordDialog(false);
    };

    const saveRecord = () => {
        setSubmitted(true);
        setRecordDialog(false);

        if (name.trim()) {
            setIsLoading(true);
            let data = {
                id: id,
                name: name,
                description: description,
                position: position
            }

            new FeatureService().saveRecord(data).then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (!response.ok) {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    } else {
                        setId(null);
                        setName(null);
                        setPosition(0);
                        setDescription(null);
                        fetchRecordsFromServer();
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    const editRecord = (record) => {
        setId(record.id);
        setName(record.name);
        setDescription(record.description);
        setPosition(record.position);
        setRecordDialog(true);
    };

    const confirmDeleteRecord = (record) => {
        setId(record.id);
        setRecord(record);
        setDeleteRecordDialog(true);
    };

    const deleteRecord = () => {
        setIsLoading(true);
        new FeatureService().deleteRecord(id)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        fetchRecordsFromServer();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{ float: "right" }}>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editRecord(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteRecord(rowData)} />
            </div>
        );
    };

    const positionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Display Position</span>
                {rowData.position}
            </>
        );
    };

    const recordDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveRecord} />
        </>
    );
    const deleteRecordDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRecordDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteRecord} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Messages ref={message} />

                    <div className=" col-12 d-flex justify-content-sm-start justify-content-md-end justify-content-lg-end justify-content-xl-end flex-wrap">
                        <div className="mr-auto p-2">
                            <h5 className="p-m-0">Manage Features</h5>
                        </div>
                        <SearchFilter
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            showInputSearchBar={true}
                            showAddNew={true}
                            showResetFilter={false}
                            onSubmitFilter={onSubmitFilter}
                            resetFilters={resetFilters}
                            onAddNew={openNew}
                        />
                    </div>

                    <hr />

                    <DataTable
                        ref={dt}
                        value={records}
                        selectionMode="single" selection={selectedRecord}
                        onSelectionChange={(e) => setSelectedRecord(e.value)}
                        rows={20}
                        rowsPerPageOptions={[20, 50, 100]}
                        globalFilter={globalFilter}
                        dataKey="id"
                        paginator={false}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        paginatorPosition="bottom"
                        paginatorAlwaysVisible="false"
                        emptyMessage="No record found."
                        loading={isLoading}
                        reflow="true"
                        responsiveLayout="scroll">
                        <Column field="Index" header="#" style={{ width: "6rem" }} body={rowIndexTemplate}></Column>
                        <Column field="name" header="Name" body={nameBodyTemplate}></Column>
                        <Column field="position" header="Display Position" body={positionBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Paginator first={first} rows={MaximumRecordsPerPage} totalRecords={totalItems} alwaysShow={false} onPageChange={onPageChange} />

                    <Dialog visible={recordDialog} style={{ width: '450px' }} header="Feature Details" modal className="p-fluid" footer={recordDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={name} onChange={(e) => setName(e.target.value || '')} required autoFocus className={classNames({ 'p-invalid': submitted && !name })} />
                            {submitted && !name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="col-12 md:col-12">
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="position">Display Position</label>
                                    <InputNumber id="position" value={position} onValueChange={(e) => setPosition(e.value || 0)}
                                        required autoFocus
                                        className={classNames({ 'p-invalid': submitted && (!position || position <= 0) })} />
                                    {submitted && (!position || position <= 0) && <small className="p-invalid">Display position is required.</small>}
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteRecordDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteRecordDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {record && (
                                <span>
                                    Are you sure you want to delete <b>{record.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default ViewFeatures;
