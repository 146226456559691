import React, {useEffect, useRef, useState} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {AccountTypeService} from "../../controllers/AccountTypeService";
import * as SystemConstants from "../../common/SystemConstants";
import {DefaultErrorMessage, MaximumRecordsPerPage} from "../../common/SystemConstants";
import {showServerError} from "../../common/MessageDialog";
import {Paginator} from "primereact/paginator";
import {SearchFilter} from "../../common/SearchFilter";
import {Messages} from "primereact/messages";
import {reloadCurrentPage} from "../../common/Utils";
import {InputSwitch} from "primereact/inputswitch";
import {Divider} from "primereact/divider";


const ViewAccountTypes = () => {
    let emptyRecord = {
        id: null,
        name: '',
        description: '',
        viewHousePlans: false,
        realEstate: false,
        construction: false,
        downloadFreeSample: false,
        purchasePlan: false,
        viewExteriorImagesOfPlan: false,
        subscribeToNewsletters: false,
        selectDesignCategory: false,
        viewFloorPlansAndAreas: false,
        priorityEmailAndPhoneSupport: false,
        constructionCostEstimates: false,
        buildingTipsAndAdvice: false,
        accessForOneYear: false,
        discountsOnPlans: false
    };

    const [records, setRecords] = useState(null);
    const [recordDialog, setRecordDialog] = useState(false);
    const [deleteRecordDialog, setDeleteRecordDialog] = useState(false);
    const [record, setRecord] = useState(emptyRecord);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const message = useRef();
    const [searchValue, setSearchValue] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(MaximumRecordsPerPage);
    const [id, setId] = useState(null);
    const [name, setName] = useState(null);

    // Regular Account
    const [viewHousePlans, setViewHousePlans] = useState(false);
    const [realEstate, setRealEstate] = useState(false);
    const [construction, setConstruction] = useState(false);
    const [downloadFreeSample, setDownloadFreeSample] = useState(false);
    const [purchasePlan, setPurchasePlan] = useState(false);
    const [viewExteriorImagesOfPlan, setViewExteriorImagesOfPlan] = useState(false);
    const [subscribeToNewsletters, setSubscribeToNewsletters] = useState(false);
    const [selectDesignCategory, setSelectDesignCategory] = useState(false);

    // Premium Account
    const [viewFloorPlansAndAreas, setViewFloorPlansAndAreas] = useState(false);
    const [priorityEmailAndPhoneSupport, setPriorityEmailAndPhoneSupport] = useState(false);
    const [constructionCostEstimates, setConstructionCostEstimates] = useState(false);
    const [buildingTipsAndAdvice, setBuildingTipsAndAdvice] = useState(false);
    const [accessForOneYear, setAccessForOneYear] = useState(false);
    const [discountsOnPlans, setDiscountsOnPlans] = useState(false);

    const templateTypes = SystemConstants.informationPageTypes;

    const fetchRecordsFromServer = () => {
        setIsLoading(true);
        new AccountTypeService().getRecords(searchValue, first, limit)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setRecords(value.records);
                        setTotalItems(value.totalItems);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
            console.log(error);
        });
    };

    const onSubmitFilter = () => {
        setSearchValue(searchValue);
        fetchRecordsFromServer();
    };

    const resetFilters = () => {
        setIsLoading(true);
        setSearchValue("");
        fetchRecordsFromServer();
    };

    useEffect(() => {
        fetchRecordsFromServer();
    }, []);

    const onPageChange = (e) => {
        let offset = e.page * MaximumRecordsPerPage;
        setFirst(e.first);
        setLimit(MaximumRecordsPerPage);
        fetchRecordsFromServer();
    };

    const rowIndexTemplate = (rowData, props) => {
        let index = first + parseInt(props.rowIndex + 1, 10);
        return (
            <React.Fragment>
                <span>{index}</span>
            </React.Fragment>
        );
    };
    const openNew = () => {
        setRecord(emptyRecord);
        setSubmitted(false);
        setRecordDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setRecordDialog(false);
    };

    const hideDeleteRecordDialog = () => {
        setDeleteRecordDialog(false);
    };

    const saveRecord = () => {
        setSubmitted(true);
        setRecordDialog(false);

        if (name.trim()) {
            setIsLoading(true);
            let data = {
                id: id,
                name: name,
                viewHousePlans: viewHousePlans,
                realEstate: realEstate,
                construction: construction,
                downloadFreeSample: downloadFreeSample,
                purchasePlan: purchasePlan,
                viewExteriorImagesOfPlan: viewExteriorImagesOfPlan,
                subscribeToNewsletters: subscribeToNewsletters,
                selectDesignCategory: selectDesignCategory,
                viewFloorPlansAndAreas: viewFloorPlansAndAreas,
                priorityEmailAndPhoneSupport: priorityEmailAndPhoneSupport,
                constructionCostEstimates: constructionCostEstimates,
                buildingTipsAndAdvice: buildingTipsAndAdvice,
                accessForOneYear: accessForOneYear,
                discountsOnPlans: discountsOnPlans
            }

            new AccountTypeService().saveRecord(data).then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (!response.ok) {

                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    } else {
                        setId(null);
                        setName(null);
                        setViewHousePlans(false);
                        setRealEstate(false);
                        setConstruction(false);
                        setDownloadFreeSample(false);
                        setPurchasePlan(false);
                        setViewExteriorImagesOfPlan(false);
                        setSubscribeToNewsletters(false);
                        setSelectDesignCategory(false);
                        setViewFloorPlansAndAreas(false);
                        setPriorityEmailAndPhoneSupport(false);
                        setConstructionCostEstimates(false);
                        setBuildingTipsAndAdvice(false);
                        setAccessForOneYear(false);
                        setDiscountsOnPlans(false);
                        fetchRecordsFromServer(); // Reload the page
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    const editRecord = (record) => {
        setId(record.id);
        setName(record.name);
        setViewHousePlans(record.viewHousePlans);
        setRealEstate(record.realEstate);
        setConstruction(record.construction);
        setDownloadFreeSample(record.downloadFreeSample);
        setPurchasePlan(record.purchasePlan);
        setViewExteriorImagesOfPlan(record.viewExteriorImagesOfPlan);
        setSubscribeToNewsletters(record.subscribeToNewsletters);
        setSelectDesignCategory(record.selectDesignCategory);
        setViewFloorPlansAndAreas(record.viewFloorPlansAndAreas);
        setPriorityEmailAndPhoneSupport(record.priorityEmailAndPhoneSupport);
        setConstructionCostEstimates(record.constructionCostEstimates);
        setBuildingTipsAndAdvice(record.buildingTipsAndAdvice);
        setAccessForOneYear(record.accessForOneYear);
        setDiscountsOnPlans(record.discountsOnPlans);
        setRecordDialog(true);
    };

    const confirmDeleteRecord = (record) => {
        setId(record.id);
        setRecord(record);
        setDeleteRecordDialog(true);
    };

    const deleteRecord = () => {
        setIsLoading(true);
        new AccountTypeService().deleteRecord(id)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        fetchRecordsFromServer();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
            console.log(error);
        });
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{float: "right"}}>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editRecord(rowData)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteRecord(rowData)}/>
            </div>
        );
    };

    const recordDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveRecord}/>
        </>
    );
    const deleteRecordDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRecordDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteRecord}/>
        </>
    );

    let spreadElements = {display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', float: 'left'};

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Messages ref={message}/>

                    <div className=" col-12 d-flex justify-content-sm-start justify-content-md-end justify-content-lg-end justify-content-xl-end flex-wrap">
                        <div className="mr-auto p-2">
                            <h5 className="p-m-0">Manage Account Types</h5>
                        </div>
                        <SearchFilter
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            showInputSearchBar={true}
                            showAddNew={true}
                            showResetFilter={false}
                            onSubmitFilter={onSubmitFilter}
                            resetFilters={resetFilters}
                            onAddNew={openNew}
                        />
                    </div>

                    <hr/>

                    <DataTable
                        ref={dt}
                        value={records}
                        selectionMode="single" selection={selectedRecord}
                        onSelectionChange={(e) => setSelectedRecord(e.value)}
                        rows={20}
                        rowsPerPageOptions={[20, 50, 100]}
                        globalFilter={globalFilter}
                        dataKey="id"
                        paginator={false}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        paginatorPosition="bottom"
                        paginatorAlwaysVisible="false"
                        emptyMessage="No record found."
                        loading={isLoading}
                        reflow="true"
                        responsiveLayout="scroll">
                        <Column field="Index" header="#" style={{width: "6rem"}} body={rowIndexTemplate}></Column>
                        <Column field="name" header="Name" body={nameBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Paginator first={first} rows={MaximumRecordsPerPage} totalRecords={totalItems} alwaysShow={false} onPageChange={onPageChange}/>

                    <Dialog visible={recordDialog} style={{width: '500px'}} header="Account Type Details" modal className="p-fluid" footer={recordDialogFooter} onHide={hideDialog}>
                        <div className="grid p-fluid">

                            <div className="col-12 md:col-12">
                                <div className="field">
                                    <label htmlFor="name">Name</label>
                                    <InputText id="name" value={name} onChange={(e) => setName(e.target.value || '')} required autoFocus className={classNames({'p-invalid': submitted && !name})}/>
                                    {submitted && !name && <small className="p-invalid">Name is required.</small>}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <Divider/>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="viewHousePlans">View House Plans</label>
                                    <InputSwitch id="viewHousePlans" checked={viewHousePlans} onChange={(e) => setViewHousePlans(e.value)}/>
                                </div>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="realEstate">Real Estate</label>
                                    <InputSwitch id="realEstate" checked={realEstate} onChange={(e) => setRealEstate(e.value)}/>
                                </div>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="construction">Construction</label>
                                    <InputSwitch id="construction" checked={construction} onChange={(e) => setConstruction(e.value)}/>
                                </div>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="downloadFreeSample">Download Free Sample</label>
                                    <InputSwitch id="downloadFreeSample" checked={downloadFreeSample} onChange={(e) => setDownloadFreeSample(e.value)}/>
                                </div>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="purchasePlan">Purchase Plan</label>
                                    <InputSwitch id="purchasePlan" checked={purchasePlan} onChange={(e) => setPurchasePlan(e.value)}/>
                                </div>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="viewExteriorImagesOfPlan">View Exterior Images of Plan</label>
                                    <InputSwitch id="viewExteriorImagesOfPlan" checked={viewExteriorImagesOfPlan} onChange={(e) => setViewExteriorImagesOfPlan(e.value)}/>
                                </div>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="subscribeToNewsletters">Subscribe to Newsletters</label>
                                    <InputSwitch id="subscribeToNewsletters" checked={subscribeToNewsletters} onChange={(e) => setSubscribeToNewsletters(e.value)}/>
                                </div>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="selectDesignCategory">Select Design Category</label>
                                    <InputSwitch id="selectDesignCategory" checked={selectDesignCategory} onChange={(e) => setSelectDesignCategory(e.value)}/>
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <Divider/>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="viewFloorPlansAndAreas">View Floor Plans and Areas</label>
                                    <InputSwitch id="viewFloorPlansAndAreas" checked={viewFloorPlansAndAreas} onChange={(e) => setViewFloorPlansAndAreas(e.value)}/>
                                </div>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="priorityEmailAndPhoneSupport">Priority Email and Phone Support</label>
                                    <InputSwitch id="priorityEmailAndPhoneSupport" checked={priorityEmailAndPhoneSupport} onChange={(e) => setPriorityEmailAndPhoneSupport(e.value)}/>
                                </div>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="constructionCostEstimates">Construction Cost Estimates</label>
                                    <InputSwitch id="constructionCostEstimates" checked={constructionCostEstimates} onChange={(e) => setConstructionCostEstimates(e.value)}/>
                                </div>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="buildingTipsAndAdvice">Building Tips and Advice</label>
                                    <InputSwitch id="buildingTipsAndAdvice" checked={buildingTipsAndAdvice} onChange={(e) => setBuildingTipsAndAdvice(e.value)}/>
                                </div>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="accessForOneYear">Access for One Year</label>
                                    <InputSwitch id="accessForOneYear" checked={accessForOneYear} onChange={(e) => setAccessForOneYear(e.value)}/>
                                </div>
                                <div className="field" style={{...spreadElements}}>
                                    <label htmlFor="discountsOnPlans">Discounts on Plans</label>
                                    <InputSwitch id="discountsOnPlans" checked={discountsOnPlans} onChange={(e) => setDiscountsOnPlans(e.value)}/>
                                </div>
                            </div>

                        </div>
                    </Dialog>

                    <Dialog visible={deleteRecordDialog} style={{width: '450px'}} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteRecordDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {record && (
                                <span>
                                    Are you sure you want to delete <b>{record.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
        ;
};

export default ViewAccountTypes;
