import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import "./../assets/css/common.css";
import {ButtonAddNewStyle, ButtonAddNewStyleSecondary} from "./Styles";

export const SearchFilter = (props) => {
    return (
        <div className="grid p-fluid" style={props.style}>
            {props.showInputSearchBar && (
                <div className="col-12 md:col-4">
          <span className="p-input-icon-left">
            <i className="pi pi-search"/>
            <InputText value={props.searchValue} onChange={(e) => props.setSearchValue(e.target.value)} placeholder="Search"/>
          </span>
                </div>
            )}
            {props.showDateRange && (
                <div className="col-12 md:col-2">
                    <Calendar
                        id="range"
                        className="width-150 "
                        dateFormat="dd/mm/yy"
                        value={props.dateRange}
                        onChange={(e) => props.setDateRange(e.value)}
                        showButtonBar
                        selectionMode="range"
                        placeholder="Date Range"
                        readOnlyInput
                        iconPos="left"
                        showIcon
                    />
                </div>
            )}
            <div className="col-12 md:col-4">
                {props.onSubmitFilter!=null &&(<Button style={{width: "100px"}} label="Search" icon="pi pi-search" iconPos="left" onClick={props.onSubmitFilter}/>)}
                {props.showResetFilter && (
                    <Button label="" style={{marginLeft: "5px"}} icon="pi pi-times" iconPos="left" onClick={props.resetFilters} className={ButtonAddNewStyleSecondary}/>
                )}
                {props.showAddNew && (
                    <Button style={{width: "120px", marginLeft: "5px"}} label="Add New" icon="pi pi-plus" className={ButtonAddNewStyle} iconPos="left" onClick={props.onAddNew}/>
                )}
            </div>

        </div>
    );
};
