import React, {useEffect, useRef, useState} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputTextarea} from 'primereact/inputtextarea';
import {InputNumber} from 'primereact/inputnumber';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {UserService} from "../../controllers/UserService";
import {DefaultErrorMessage, MaximumRecordsPerPage} from "../../common/SystemConstants";
import {showServerError} from "../../common/MessageDialog";
import {Paginator} from "primereact/paginator";
import {SearchFilter} from "../../common/SearchFilter";
import {Messages} from "primereact/messages";
import {reloadCurrentPage, ThousandsSeparatorNoCurrency} from "../../common/Utils";
import {CostCalculatorItemService} from "../../controllers/CostCalculatorItemService";
import {MultiSelect} from 'primereact/multiselect';
import {AccountTypeService} from "../../controllers/AccountTypeService";
import {isValidPhoneNumber} from 'libphonenumber-js';


const ViewUsers = () => {
    let emptyRecord = {
        id: null,
        name: '',
        description: '',
        pricePerSquareMeter: 0
    };

    const [records, setRecords] = useState(null);
    const [recordDialog, setRecordDialog] = useState(false);
    const [deleteRecordDialog, setDeleteRecordDialog] = useState(false);
    const [record, setRecord] = useState(emptyRecord);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const message = useRef();
    const [searchValue, setSearchValue] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(MaximumRecordsPerPage);
    const [id, setId] = useState(null);

    const [fullName, setFullName] = useState(null);
    const [emailAddress, setEmailAddress] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);

    const [selectedRoles, setSelectedRoles] = useState([]);
    const [role, setRoles] = useState([]);
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const fetchRecordsFromServer = () => {
        setIsLoading(true);
        new UserService().getRecords(searchValue, first, limit)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setRecords(value.records);
                        setTotalItems(value.totalItems);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
            console.log(error);
        });

        new UserService().getRoles()
            .then(async (response) => {
                response.json().then((value) => {
                    if (response.ok) {
                        setRoles(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
            console.log(error);
        });
    };

    const onSubmitFilter = () => {
        setSearchValue(searchValue);
        fetchRecordsFromServer();
    };

    const resetFilters = () => {
        setIsLoading(true);
        setSearchValue("");
        fetchRecordsFromServer();
    };

    useEffect(() => {
        fetchRecordsFromServer();
    }, []);

    const onPageChange = (e) => {
        let offset = e.page * MaximumRecordsPerPage;
        setFirst(e.first);
        setLimit(MaximumRecordsPerPage);
        fetchRecordsFromServer();
    };


    const openNew = () => {
        setRecord(emptyRecord);
        setSubmitted(false);
        setRecordDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setRecordDialog(false);
    };

    const hideDeleteRecordDialog = () => {
        setDeleteRecordDialog(false);
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validatePhoneNumber = (number) => {
        return isValidPhoneNumber(number);
    };

    const saveRecord = () => {
        setSubmitted(true);
        setRecordDialog(false);

        setIsLoading(true);
        let data = {
            id: id,
            fullName: fullName,
            email: emailAddress,
            phoneNumber: phoneNumber,
            username: username,
            password: password,
            confirmPassword: confirmPassword,
            role: selectedRoles
        }

        console.log(data);
        new UserService().saveUser(data).then(async (response) => {
            setIsLoading(false);
            response.json().then((value) => {
                if (!response.ok) {
                    setFullName(null);
                    setEmailAddress(null);
                    setPhoneNumber(null);
                    setUsername(null);
                    setSelectedRoles(null);
                    setPassword(null);
                    setConfirmPassword(null);

                    showServerError(response.status, value ? value : DefaultErrorMessage, message);
                } else {
                    reloadCurrentPage(); // Reload the page
                }
            });
        }).catch((error) => {
            console.log(error);
        });
    };

    const editRecord = (record) => {
        setId(record.id);
        setFullName(record.fullName);
        setEmailAddress(record.email);
        setPhoneNumber(record.phoneNumber);
        setUsername(record.username);
        setSelectedRoles(record.roles);
        setRecordDialog(true);
    };

    const confirmDeleteRecord = (record) => {
        setId(record.id);
        setRecord(record);
        setDeleteRecordDialog(true);
    };

    const deleteRecord = () => {
        setIsLoading(true);
        new UserService().deleteUser(id)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        reloadCurrentPage();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
            console.log(error);
        });
    };
    const rowIndexTemplate = (rowData, props) => {
        let index = first + parseInt(props.rowIndex + 1, 10);
        return (
            <React.Fragment>
                <span>{index}</span>
            </React.Fragment>
        );
    };

    const fullNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Full Name</span>
                {rowData.fullName}
            </>
        );
    };

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </>
        );
    };

    const usernameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Username</span>
                {rowData.username}
            </>
        );
    };

    const phoneNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Phone Number</span>
                {rowData.phoneNumber}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{float: "right"}}>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editRecord(rowData)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteRecord(rowData)}/>
            </div>
        );
    };

    const recordDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveRecord}/>
        </>
    );
    const deleteRecordDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRecordDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteRecord}/>
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Messages ref={message}/>

                    <div className=" col-12 d-flex justify-content-sm-start justify-content-md-end justify-content-lg-end justify-content-xl-end flex-wrap">
                        <div className="mr-auto p-2">
                            <h5 className="p-m-0">Manage Users</h5>
                        </div>
                        <SearchFilter
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            showInputSearchBar={true}
                            showAddNew={true}
                            showResetFilter={false}
                            onSubmitFilter={onSubmitFilter}
                            resetFilters={resetFilters}
                            onAddNew={openNew}
                        />
                    </div>

                    <hr/>

                    <DataTable
                        ref={dt}
                        value={records}
                        selectionMode="single" selection={selectedRecord}
                        onSelectionChange={(e) => setSelectedRecord(e.value)}
                        rows={20}
                        rowsPerPageOptions={[20, 50, 100]}
                        globalFilter={globalFilter}
                        dataKey="id"
                        paginator={false}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        paginatorPosition="bottom"
                        paginatorAlwaysVisible="false"
                        emptyMessage="No record found."
                        loading={isLoading}
                        reflow="true"
                        responsiveLayout="scroll">
                        <Column field="Index" header="#" style={{width: "6rem"}} body={rowIndexTemplate}></Column>
                        <Column field="fullName" header="Full Name" body={fullNameBodyTemplate} headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                        <Column field="email" header="Email" body={emailBodyTemplate} headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                        <Column field="phoneNumber" header="Phone Number" body={phoneNumberBodyTemplate} headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                        <Column field="username" header="Username" body={usernameBodyTemplate} headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Paginator first={first} rows={MaximumRecordsPerPage} totalRecords={totalItems} alwaysShow={false} onPageChange={onPageChange}/>

                    <Dialog visible={recordDialog} style={{width: '750px'}} header="User Details" modal className="p-fluid" footer={recordDialogFooter} onHide={hideDialog}>
                        <div className="grid p-fluid">
                            <div className="col-12 md:col-12">
                                <div className="field">
                                    <label htmlFor="fullName">Full Name</label>
                                    <InputText id="fullName" value={fullName}
                                               onChange={(e) => setFullName(e.target.value || '')}
                                               required autoFocus className={classNames({'p-invalid': submitted && !fullName})}/>
                                    {submitted && !fullName && <small className="p-invalid">Full Name is required.</small>}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="emailAddress">Email Address</label>
                                    <InputText id="emailAddress" value={emailAddress}
                                               onChange={(e) => setEmailAddress(e.target.value || '')}
                                               required autoFocus className={classNames({'p-invalid': submitted && (!emailAddress || emailError)})}
                                    />
                                    {submitted && !emailAddress && (<small className="p-invalid">Email Address is required.</small>)}
                                    {submitted && emailAddress && emailError && (<small className="p-invalid">{emailError}</small>)}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <InputText id="phoneNumber" value={phoneNumber}
                                               onChange={(e) => setPhoneNumber(e.target.value || '')}
                                               required autoFocus className={classNames({'p-invalid': submitted && (!phoneNumber || phoneError)})}/>
                                    {submitted && !phoneNumber && (<small className="p-invalid">Phone Number is required.</small>)}
                                    {submitted && phoneNumber && phoneError && (<small className="p-invalid">{phoneError}</small>)}
                                </div>
                            </div>


                            <div className="col-12 md:col-12">
                                <div className="field">
                                    <label htmlFor="selectedRoles">Roles</label>
                                    <MultiSelect id="selectedRoles" value={selectedRoles}
                                                 onChange={(e) => setSelectedRoles(e.value)}
                                                 options={role}
                                                 optionLabel="name" display="chip"
                                                 placeholder="Select User Roles"
                                                 maxSelectedLabels={10}/>
                                </div>
                            </div>

                            <div className="col-12 md:col-12">
                                <div className="field">
                                    <label htmlFor="username">Username</label>
                                    <InputText id="username" value={username}
                                               onChange={(e) => setUsername(e.target.value || '')}
                                               required autoFocus className={classNames({'p-invalid': submitted && !username})}/>
                                    {submitted && !username && <small className="p-invalid">Username is required.</small>}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="username">Password</label>
                                    <InputText id="password" value={password}
                                               type="password"
                                               onChange={(e) => setPassword(e.target.value || '')}
                                               required={true} autoFocus className={classNames({'p-invalid': submitted && !password})}/>
                                    {submitted && !password && <small className="p-invalid">Password is required.</small>}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="confirmPassword">Confirm Password</label>
                                    <InputText id="confirmPassword" value={confirmPassword}
                                               type="password"
                                               onChange={(e) => setConfirmPassword(e.target.value || '')}
                                               required={true} autoFocus className={classNames({'p-invalid': submitted && !confirmPassword})}/>
                                    {submitted && !confirmPassword && <small className="p-invalid">Confirm Password is required.</small>}
                                </div>
                            </div>

                        </div>
                    </Dialog>

                    <Dialog visible={deleteRecordDialog} style={{width: '450px'}} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteRecordDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {record && (
                                <span>
                                    Are you sure you want to delete <b>{record.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default ViewUsers;
