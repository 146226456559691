import React from 'react';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';
import { clearLocalStorageAndLogout } from './common/Utils';
import {UIHomePath} from "./routes/Frontend";

const AppTopbar = (props) => {
    const navigate = useNavigate();
    const topbarMenuClassName = classNames('topbar-menu fadeInDown', { 'topbar-menu-visible': props.topbarMenuActive });
    const profileItemClassName = classNames('user-profile', { 'active-topmenuitem': props.activeTopbarItem === 'profile' });
    const activeTopbarItemClassName = (name) => {
        return name === props.activeTopbarItem ? 'active-topmenuitem' : null;
    };

    const logoutHandler = () => {
        clearLocalStorageAndLogout();
    };

    const isProfilePopup = props.profileMode === 'popup' || props.isHorizontal;

    return (
        <div className="layout-topbar">
            <button className="layout-topbar-logo p-link" onClick={() => navigate(UIHomePath)}>
                <img id="layout-topbar-logo" src="assets/layout/images/logo-white.png" alt="babylon-layout" />
            </button>

            <button className="layout-menu-button p-link" onClick={props.onMenuButtonClick}>
                <i className="pi pi-bars"></i>
            </button>

            <button id="topbar-menu-button" className="p-link" onClick={props.onTopbarMenuButtonClick}>
                <i className="pi pi-ellipsis-v"></i>
            </button>

            <ul className={topbarMenuClassName}>
                {isProfilePopup && (
                    <li className={profileItemClassName}>
                        <button className="p-link" onClick={(e) => props.onTopbarItemClick(e, 'profile')}>
                            <img alt="babylon-layout" src="assets/layout/images/avatar.png" />
                            <span className="topbar-item-name">Arlene Welch</span>
                        </button>

                        <ul className={classNames({ fadeInDown: !props.isMobile() })}>
                            {/*<li role="menuitem">
                                <button className="p-link">
                                    <i className="pi pi-user"></i>
                                    <span>Profile</span>
                                </button>
                            </li>*/}
                            <li role="menuitem">
                                <button className="p-link" onClick={logoutHandler}>
                                    <i className="pi pi-sign-out"></i>
                                    <span>Logout</span>
                                </button>
                            </li>
                        </ul>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default AppTopbar;
