import React, {useEffect, useRef, useState} from 'react';
import {Dropdown} from 'primereact/dropdown';
import {InputNumber} from 'primereact/inputnumber';
import {CostCalculatorCategoryService} from "../../controllers/CostCalculatorCategoryService";
import {showServerError} from "../../common/MessageDialog";
import {DefaultErrorMessage} from "../../common/SystemConstants";
import {classNames} from "primereact/utils";
import {Button} from "primereact/button";
import {Messages} from "primereact/messages";
import {useForm} from "react-hook-form";
import {Divider} from "primereact/divider";
import {ThousandsSeparatorNoCurrency} from "../../common/Utils";
import {DataView} from "primereact/dataview";

const ViewCostCalculator = () => {

    const [costCalculatorCategories, setCostCalculatorCategories] = useState([]);
    const [costCalculatorCategory, setCostCalculatorCategory] = useState([]);
    const [squareMeter, setSquareMeter] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [hasResult, setHasResult] = useState(false);
    const [costCalculatorResult, setCostCalculatorResult] = useState([]);
    const message = useRef();
    const dt = useRef(null);
    const [first, setFirst] = useState(0);
    const [layout, setLayout] = useState('grid');
    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm();

    function submitHandler(data, event) {
        event.preventDefault();
        setIsLoading(true);
        setCostCalculatorCategory(costCalculatorCategory);

        let jsonData = {
            calculatorCategoryId: costCalculatorCategory.id,
            squareMeters: squareMeter
        }
        new CostCalculatorCategoryService().generateCalculator(jsonData).then(async (response) => {
            setIsLoading(false);
            response.json().then((value) => {
                if (!response.ok) {
                    showServerError(response.status, value ? value : DefaultErrorMessage, message);
                } else {
                    setHasResult(true);
                    setCostCalculatorResult(value);
                }
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    const fetchRecordsFromServer = () => {
        setIsLoading(true);
        new CostCalculatorCategoryService().getRecords(null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setCostCalculatorCategories(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
            console.log(error);
        });
    };

    const itemTemplate = (product, layout, index) => {
        if (!product) {
            return;
        }
        if (layout === 'list') return gridItem(product);
        else if (layout === 'grid') return gridItem(product);
    };

    const gridItem = (item) => {
        return (
            <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2" key={item.id}>
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                        <div className="flex align-items-center gap-2">
                            <i className="pi pi-tag"></i>
                            <span>{item.name}</span>
                        </div>
                    </div>
                    <div className="flex align-items-center justify-content-between" style={{paddingTop: "0.5em"}}>
                        <span>Percentage : {item.percentage}%</span>
                    </div>
                    <div className="flex align-items-center justify-content-between" style={{paddingTop: "0.5em"}}>
                        <span>Total Estimated Amount : {ThousandsSeparatorNoCurrency(item.totalValue)}</span>
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        fetchRecordsFromServer();
    }, []);

    return (
        <div className="grid p-fluid card">
            <Messages ref={message}/>
            <form onSubmit={handleSubmit(submitHandler)} style={{width: "100%"}}>

                <div className="grid formgrid" style={{margin: "2em"}}>
                    <div className="col-12 mb-2 lg:col-4">
                        <h6>Cost Calculator Category</h6>
                        <Dropdown
                            value={costCalculatorCategory}
                            onChange={(e) => setCostCalculatorCategory(e.value)}
                            options={costCalculatorCategories}
                            optionLabel="name" placeholder="Select"/>
                    </div>
                    <div className="col-12 mb-2 lg:col-4">
                        <h6>Square Meters</h6>
                        <InputNumber id="squareMeter" value={squareMeter} onValueChange={(e) => setSquareMeter(e.value || 0)} required autoFocus className={classNames({'p-invalid': submitted && (!squareMeter || squareMeter <= 0)})}/>
                        {submitted && (!squareMeter || squareMeter <= 0) && <small className="p-invalid">Square Meters are required.</small>}

                    </div>
                    <div className="col-12 mb-2 lg:col-4">
                        <Button label="Calculate" style={{width: "30%", float: "left", marginTop: "2.30em"}}/>
                    </div>

                    {hasResult && (<div className="col-12 mb-2 lg:col-12" style={{marginTop: "2em"}}>
                        <span>Total Estimated Amount : {ThousandsSeparatorNoCurrency(costCalculatorResult.totalEstimatedAmount)}</span>
                        <Divider/>

                        <DataView
                            value={costCalculatorResult.calculatorItems}
                            itemTemplate={itemTemplate}
                            layout={layout}/>

                    </div>)}
                </div>
            </form>
        </div>
    );
};

export default ViewCostCalculator;
