import {LoginPath} from '../routes/Frontend';
import {KeyBearerToken} from './SystemConstants';

/**
 * This method is used to replace all spaces with a dash and also convert the resultant value to lowercase.
 *
 * @param value
 * @returns
 */
export const ReplaceWithUnderscore = (value) => {
    if (value) return value.replace(/\s+/g, "-").replace("_", " ");
};

export const capitalizeWord = (text) => {
    if (text) return text.toLowerCase().replace(/(?<=(?:^|[.?!])\W*)[a-z]/g, (i) => i.toUpperCase());
};

/**
 * This method is used to format the provided number into the standard number format.
 *
 * @param value
 * @returns
 */
export const ThousandsSeparator = (value) => {
    // Create our number formatter.
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        // These options are needed to round to whole numbers.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(value);
};

/**
 * This method is used to format the provided number into the standard number format wihout currency.
 *
 * @param value
 * @returns
 */
export const ThousandsSeparatorNoCurrency = (value) => {
    if (value == null)
        return 0;
    // Create our number formatter.
    var formatter = new Intl.NumberFormat("en-US", {
        // These options are needed to round to whole numbers.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(value);
};

/**
 * This method is used to clear the local storage and redirect the user to the login screen.
 *
 */
export function clearLocalStorageAndLogout() {
    localStorage.clear();
    window.location.href = LoginPath;
}

/**
 * This method is used to reload the current page
 */
export function reloadCurrentPage() {
    window.location.reload();
}

/**
 * This method is used to convert a string to a json object.
 *
 * @param {string} value
 * @returns
 */
export function toJSONObject(value) {
    return JSON.parse(value);
}

/**
 * This method is used to convert a JSON object into a string.
 *
 * @param value
 * @returns
 */
export function toJSONString(value) {
    return JSON.stringify(value);
}

/**
 * This method is used to get a bearer token from local storage
 * @returns string
 */
export function getBearerToken() {
    return localStorage.getItem(KeyBearerToken) ? `${localStorage.getItem(KeyBearerToken)}` : null;
}

export function isNullOrEmpty(str) {
    return !str || str.trim() === '';
}

export function isLoggedIn() {
    var token = getBearerToken();
    return !isNullOrEmpty(token);
}

export function formatBooleanFields(value) {
    if (value === null) return "-";
    else if (value === true) return "Yes";
    else if (value === false) return "No";
}

export const formatCurrency = (value) => {
    return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
};
