import React, {useEffect, useRef, useState} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {InputTextarea} from 'primereact/inputtextarea';
import {InputNumber} from 'primereact/inputnumber';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {SizeService} from "../../controllers/SizeService";
import {DefaultErrorMessage, MaximumRecordsPerPage} from "../../common/SystemConstants";
import {showServerError} from "../../common/MessageDialog";
import {Paginator} from "primereact/paginator";
import {SearchFilter} from "../../common/SearchFilter";
import {Messages} from "primereact/messages";
import {reloadCurrentPage, ThousandsSeparatorNoCurrency} from "../../common/Utils";


const ViewSizes = () => {
    let emptyRecord = {
        id: null,
        name: '',
        description: '',
        sizeNumber: 0
    };

    const [records, setRecords] = useState(null);
    const [recordDialog, setRecordDialog] = useState(false);
    const [deleteRecordDialog, setDeleteRecordDialog] = useState(false);
    const [record, setRecord] = useState(emptyRecord);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const message = useRef();
    const [searchValue, setSearchValue] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(MaximumRecordsPerPage);
    const [id, setId] = useState(null);
    const [name, setName] = useState("");
    const [sizeNumber, setSizeNumber] = useState(0);
    const [description, setDescription] = useState("");

    const fetchRecordsFromServer = () => {
        setIsLoading(true);
        new SizeService().getRecords(searchValue, first, limit)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setRecords(value.records);
                        setTotalItems(value.totalItems);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
            console.log(error);
        });
    };

    const onSubmitFilter = () => {
        setSearchValue(searchValue);
        fetchRecordsFromServer();
    };

    const resetFilters = () => {
        setIsLoading(true);
        setSearchValue("");
        fetchRecordsFromServer();
    };

    useEffect(() => {
        fetchRecordsFromServer();
    }, []);

    const onPageChange = (e) => {
        let offset = e.page * MaximumRecordsPerPage;
        setFirst(e.first);
        setLimit(MaximumRecordsPerPage);
        fetchRecordsFromServer();
    };

    const rowIndexTemplate = (rowData, props) => {
        let index = first + parseInt(props.rowIndex + 1, 10);
        return (
            <React.Fragment>
                <span>{index}</span>
            </React.Fragment>
        );
    };
    const openNew = () => {
        setRecord(emptyRecord);
        setSubmitted(false);
        setRecordDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setRecordDialog(false);
    };

    const hideDeleteRecordDialog = () => {
        setDeleteRecordDialog(false);
    };

    const saveRecord = () => {
        setSubmitted(true);
        setRecordDialog(false);

        if (name.trim()) {
            setIsLoading(true);
            let data = {
                id: id,
                name: name,
                description: description,
                sizeNumber: sizeNumber
            }

            new SizeService().saveRecord(data).then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (!response.ok) {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    } else {
                        setId(null);
                        setName(null);
                        setDescription(null);
                        setSizeNumber(0);
                        fetchRecordsFromServer();
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    const editRecord = (record) => {
        setId(record.id);
        setName(record.name);
        setDescription(record.description);
        setSizeNumber(record.sizeNumber);
        setRecordDialog(true);
    };

    const confirmDeleteRecord = (record) => {
        setId(record.id);
        setRecord(record);
        setDeleteRecordDialog(true);
    };

    const deleteRecord = () => {
        setIsLoading(true);
        new SizeService().deleteRecord(id)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        fetchRecordsFromServer();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
            console.log(error);
        });
    };

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Description</span>
                {rowData.description}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    };

    const sizeNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">sizeNumber</span>
                {ThousandsSeparatorNoCurrency(rowData.sizeNumber)}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{float: "right"}}>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editRecord(rowData)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteRecord(rowData)}/>
            </div>
        );
    };

    const recordDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveRecord}/>
        </>
    );
    const deleteRecordDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRecordDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteRecord}/>
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Messages ref={message}/>

                    <div className=" col-12 d-flex justify-content-sm-start justify-content-md-end justify-content-lg-end justify-content-xl-end flex-wrap">
                        <div className="mr-auto p-2">
                            <h5 className="p-m-0">Manage Sizes</h5>
                        </div>
                        <SearchFilter
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            showInputSearchBar={true}
                            showAddNew={true}
                            showResetFilter={false}
                            onSubmitFilter={onSubmitFilter}
                            resetFilters={resetFilters}
                            onAddNew={openNew}
                        />
                    </div>

                    <hr/>

                    <DataTable
                        ref={dt}
                        value={records}
                        selectionMode="single" selection={selectedRecord}
                        onSelectionChange={(e) => setSelectedRecord(e.value)}
                        rows={20}
                        rowsPerPageOptions={[20, 50, 100]}
                        globalFilter={globalFilter}
                        dataKey="id"
                        paginator={false}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        paginatorPosition="bottom"
                        paginatorAlwaysVisible="false"
                        emptyMessage="No record found."
                        loading={isLoading}
                        reflow="true"
                        responsiveLayout="scroll">
                        <Column field="Index" header="#" style={{width: "6rem"}} body={rowIndexTemplate}></Column>
                        <Column field="name" header="Name" body={nameBodyTemplate} headerStyle={{width: '14%', minWidth: '10rem'}}></Column>
                        <Column field="sizeNumber" header="Size Number" body={sizeNumberBodyTemplate} headerStyle={{width: '14%', minWidth: '8rem'}}></Column>
                        <Column field="description" header="Description" body={descriptionBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Paginator first={first} rows={MaximumRecordsPerPage} totalRecords={totalItems} alwaysShow={false} onPageChange={onPageChange}/>

                    <Dialog visible={recordDialog} style={{width: '450px'}} header="Size Details" modal className="p-fluid" footer={recordDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={name} onChange={(e) => setName(e.target.value || '')} required autoFocus className={classNames({'p-invalid': submitted && !name})}/>
                            {submitted && !name && <small className="p-invalid">Name is required.</small>}
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="sizeNumber">Size Number</label>
                                <InputNumber id="sizeNumber" value={sizeNumber} onValueChange={(e) => setSizeNumber(e.value || 0)} required autoFocus className={classNames({'p-invalid': submitted && (!sizeNumber || sizeNumber <= 0)})}/>
                                {submitted && (!sizeNumber || sizeNumber <= 0) && <small className="p-invalid">Size is required.</small>}
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <InputTextarea id="description" value={description} onChange={(e) => setDescription(e.target.value || '')} required rows={3} cols={20}/>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteRecordDialog} style={{width: '450px'}} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteRecordDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {record && (
                                <span>
                                    Are you sure you want to delete <b>{record.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default ViewSizes;
