import {BaseApiClient} from "./BaseApiClient";
import {MaximumRecordsPerPage} from "../common/SystemConstants";
import {EndpointCostCalculator, EndpointDeleteCostCalculatorCategory, EndpointGetCostCalculatorCategories, EndpointSaveCostCalculatorCategory, EndpointSaveCostCalculatorItem} from "../routes/Backend";

export class CostCalculatorCategoryService extends BaseApiClient {

    /**
     *
     * @param {*} jsonData
     * @returns
     */
    async saveRecord(jsonData) {
        return await fetch(EndpointSaveCostCalculatorCategory, this.PostRequestDetails(jsonData));
    }

    /**
     *
     * @param jsonData
     * @returns {Promise<Response>}
     */
    async generateCalculator(jsonData) {
        return await fetch(EndpointCostCalculator, this.PostRequestDetails(jsonData));
    }

    /**
     *
     * @param searchTerm
     * @param offset
     * @param limit
     * @returns {Promise<Response>}
     * @constructor
     */
    async getRecords(searchTerm, offset, limit = MaximumRecordsPerPage) {
        let searchParameters = {offset: offset, limit: limit};
        if (searchTerm !== null) searchParameters.searchTerm = searchTerm;
        return await fetch(EndpointGetCostCalculatorCategories + new URLSearchParams(searchParameters), this.GetRequestDetails());
    }

    /**
     *
     * @param id
     * @returns {Promise<Response>}
     */
    async deleteRecord(id) {
        let data = {};
        console.log(id);
        return await fetch(EndpointDeleteCostCalculatorCategory.replace(":id", id), this.PostRequestDetails(data));
    }

}
