import React, {useEffect, useRef, useState} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {NewsLetterService} from "../../controllers/NewsLetterService";
import {DefaultErrorMessage, MaximumRecordsPerPage} from "../../common/SystemConstants";
import {showServerError} from "../../common/MessageDialog";
import {Paginator} from "primereact/paginator";
import {SearchFilter} from "../../common/SearchFilter";
import {Messages} from "primereact/messages";
import {ReplaceWithUnderscore} from "../../common/Utils";
import {Editor} from 'primereact/editor';


const ViewNewsLetters = () => {
    let emptyRecord = {
        id: null,
        title: '',
        content: ''
    };

    const [records, setRecords] = useState(null);
    const [recordDialog, setRecordDialog] = useState(false);
    const [deleteRecordDialog, setDeleteRecordDialog] = useState(false);
    const [record, setRecord] = useState(emptyRecord);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const message = useRef();
    const [searchValue, setSearchValue] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(MaximumRecordsPerPage);
    const [id, setId] = useState(null);
    const [title, setTitle] = useState(null);
    const [content, setContent] = useState(null);

    const fetchRecordsFromServer = () => {
        setIsLoading(true);
        new NewsLetterService().getRecords(searchValue, first, limit)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setRecords(value.records);
                        setTotalItems(value.totalItems);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
            console.log(error);
        });
    };

    const onSubmitFilter = () => {
        setSearchValue(searchValue);
        fetchRecordsFromServer();
    };

    const resetFilters = () => {
        setIsLoading(true);
        setSearchValue("");
        fetchRecordsFromServer();
    };

    useEffect(() => {
        fetchRecordsFromServer();
    }, []);

    const onPageChange = (e) => {
        let offset = e.page * MaximumRecordsPerPage;
        setFirst(e.first);
        setLimit(MaximumRecordsPerPage);
        fetchRecordsFromServer();
    };

    const rowIndexTemplate = (rowData, props) => {
        let index = first + parseInt(props.rowIndex + 1, 10);
        return (
            <React.Fragment>
                <span>{index}</span>
            </React.Fragment>
        );
    };
    const openNew = () => {
        setRecord(emptyRecord);
        setSubmitted(false);
        setRecordDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setRecordDialog(false);
    };

    const hideDeleteRecordDialog = () => {
        setDeleteRecordDialog(false);
    };

    const saveRecord = () => {
        setSubmitted(true);
        setRecordDialog(false);

        if (title.trim() && content.trim()) {
            setIsLoading(true);
            let data = {
                id: id,
                title: title,
                content: content
            }

            new NewsLetterService().saveRecord(data).then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (!response.ok) {
                        setId(null);
                        setTitle(null);
                        setContent(null);

                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    } else {
                        fetchRecordsFromServer(); // Reload the page
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    const editRecord = (record) => {
        setId(record.id);
        setTitle(record.title);
        setContent(record.content);
        setRecordDialog(true);
    };

    const confirmDeleteRecord = (record) => {
        setId(record.id);
        setRecord(record);
        setDeleteRecordDialog(true);
    };

    const deleteRecord = () => {
        setIsLoading(true);
        new NewsLetterService().deleteRecord(id)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        fetchRecordsFromServer();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
            console.log(error);
        });
    };

    const templateTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Template Type</span>
                {ReplaceWithUnderscore(rowData.templateType)}
            </>
        );
    };

    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Title</span>
                {rowData.title}
            </>
        );
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date Created</span>
                {rowData.dateCreated}
            </>
        );
    };

    const numberOfRecipientsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Number Of Recipients</span>
                {rowData.numberOfRecipients}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{float: "right"}}>
                <Button icon="pi pi-eye" className="p-button-rounded p-button-success mr-2" onClick={() => editRecord(rowData)}/>
            </div>
        );
    };

    const recordDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog}/>
        </>
    );
    const deleteRecordDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRecordDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteRecord}/>
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Messages ref={message}/>

                    <div className=" col-12 d-flex justify-content-sm-start justify-content-md-end justify-content-lg-end justify-content-xl-end flex-wrap">
                        <div className="mr-auto p-2">
                            <h5 className="p-m-0">Manage News Letters</h5>
                        </div>
                        <SearchFilter
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            showInputSearchBar={true}
                            showAddNew={true}
                            showResetFilter={false}
                            onSubmitFilter={onSubmitFilter}
                            resetFilters={resetFilters}
                            onAddNew={openNew}
                        />
                    </div>

                    <hr/>

                    <DataTable
                        ref={dt}
                        value={records}
                        selectionMode="single" selection={selectedRecord}
                        onSelectionChange={(e) => setSelectedRecord(e.value)}
                        rows={20}
                        rowsPerPageOptions={[20, 50, 100]}
                        globalFilter={globalFilter}
                        dataKey="id"
                        paginator={false}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        paginatorPosition="bottom"
                        paginatorAlwaysVisible="false"
                        emptyMessage="No record found."
                        loading={isLoading}
                        reflow="true"
                        responsiveLayout="scroll">
                        <Column field="Index" header="#" style={{width: "6rem"}} body={rowIndexTemplate}></Column>
                        <Column field="dateCreated" header="Date Creted" body={dateCreatedBodyTemplate}></Column>
                        <Column field="title" header="Title" body={titleBodyTemplate}></Column>
                        <Column field="numberOfRecipients" header="Number Of Recipients" body={numberOfRecipientsBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Paginator first={first} rows={MaximumRecordsPerPage} totalRecords={totalItems} alwaysShow={false} onPageChange={onPageChange}/>

                    <Dialog visible={recordDialog} style={{width: '880px'}} header="News Letter Details" modal className="p-fluid" footer={recordDialogFooter} onHide={hideDialog}>
                        <div className="grid p-fluid">
                            <div className="col-12 md:col-12">
                                <div className="field">
                                    <label htmlFor="title">Title</label>
                                    <InputText id="title" value={title} onChange={(e) => setTitle(e.target.value || '')} required autoFocus className={classNames({'p-invalid': submitted && !title})}/>
                                    {submitted && !title && <small className="p-invalid">Title is required.</small>}
                                </div>
                            </div>
                            <div className="col-12 md:col-12">
                                <div className="field">
                                    <label htmlFor="content">Content</label>
                                    <Editor id="content" value={content}
                                            onTextChange={(e) => setContent(e.htmlValue)}
                                            style={{height: '260px'}}/>
                                    {submitted && !content && <small className="p-invalid">Content is required.</small>}
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteRecordDialog} style={{width: '450px'}} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteRecordDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                            {record && (
                                <span>
                                    Are you sure you want to delete <b>{record.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
        ;
};

export default ViewNewsLetters;
