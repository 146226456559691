import {BaseApiClient} from "./BaseApiClient";
import {
    EndpointChangePassword,
    EndpointDeleteRole,
    EndpointDeleteStyle, EndpointDeleteUser,
    EndpointGetClients,
    EndpointGetLookups,
    EndpointGetPermissions,
    EndpointGetRoles,
    EndpointInitiatePasswordReset,
    EndpointLogin,
    EndpointSaveRole, EndpointSaveSize,
    EndpointSaveStyle, EndpointSaveUser,
    EndpointUpdateEmail,
    EndpointUpdateProfile
} from "../routes/Backend";
import {MaximumRecordsPerPage} from "../common/SystemConstants";

export class UserService extends BaseApiClient {

    /**
     *
     * @param {*} username
     * @param {*} password
     * @returns
     */
    async Login(username, password) {
        let data = {
            username: username,
            password: password,
        };
        return await fetch(EndpointLogin, this.PublicPostRequestDetails(data));
    }

    /**
     *
     * @param {*} currentEmail
     * @param {*} newEmail
     * @returns
     */
    async updateEmail(currentEmail, newEmail) {
        let data = {
            currentEmail: currentEmail,
            email: newEmail,
        };
        return await fetch(EndpointUpdateEmail, this.PostRequestDetails(data));
    }

    /**
     *
     * @param {*} jsonData
     * @returns
     */
    async updateProfile(jsonData) {
        return await fetch(EndpointUpdateProfile, this.PostRequestDetails(jsonData));
    }

    /**
     *
     * @param {*} userIdentifier
     * @returns
     */
    async initiatePasswordReset(userIdentifier) {
        let data = {
            userIdentifier: userIdentifier,
        };
        return await fetch(EndpointInitiatePasswordReset, this.PostRequestDetails(data));
    }

    /**
     *
     * @param {*} accessToken
     * @param {*} password
     * @param {*} confirmPassword
     * @returns
     */
    async confirmPasswordReset(accessToken, password, confirmPassword) {
        let data = {
            accessToken: accessToken,
            password: password,
            confirmPassword: confirmPassword,
        };
        return await fetch(EndpointChangePassword, this.PostRequestDetails(data));
    }

    /**
     *
     * @param jsonData
     * @returns {Promise<Response>}
     */
    async saveRole(jsonData) {
        return await fetch(EndpointSaveRole, this.PostRequestDetails(jsonData));
    }

    /**
     *
     * @param jsonData
     * @returns {Promise<Response>}
     */
    async saveUser(jsonData) {
        return await fetch(EndpointSaveUser, this.PostRequestDetails(jsonData));
    }

    /**
     *
     * @returns
     */
    async getRoles() {
        return await fetch(EndpointGetRoles, this.GetRequestDetails());
    }

    /**
     *
     * @returns
     */
    async getPermissions() {
        return await fetch(EndpointGetPermissions, this.GetRequestDetails());
    }
    /**
     *
     * @returns
     */
    async getLookups() {
        return await fetch(EndpointGetLookups, this.GetRequestDetails());
    }

    /**
     *
     * @param searchTerm
     * @param offset
     * @param limit
     * @returns {Promise<Response>}
     */
    async getRecords(searchTerm, offset, limit = MaximumRecordsPerPage) {
        let searchParameters = {offset: offset, limit: limit};
        if (searchTerm !== null) searchParameters.searchTerm = searchTerm;
        return await fetch(EndpointGetClients + new URLSearchParams(searchParameters), this.GetRequestDetails());
    }

    async deleteRole(id) {
        let data = {};
        console.log(id);
        return await fetch(EndpointDeleteRole.replace(":id", id), this.PostRequestDetails(data));
    }

    async deleteUser(id) {
        let data = {};
        console.log(id);
        return await fetch(EndpointDeleteUser.replace(":id", id), this.PostRequestDetails(data));
    }

}
