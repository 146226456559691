export const MaximumErrorMessageDisplayTimeInSeconds = 8000;
export const DefaultColor = "#4c0a19";
export const MaximumRecordsPerPage = 10;
export const KeyBearerToken = "Authorization";
export const KeyUserDetails = "UserDetails";

export const DefaultErrorMessage = {
    status: "Failed",
    message: "Something went wrong. Please try again or contact support",
};

export const informationPageTypes = [
    {label: "About Us", value: "ABOUT_US"},
    {label: "Return Policy", value: "RETURN_POLICY"},
    {label: "Terms & Conditions", value: "TERMS_AND_CONDITIONS"},
    {label: "Privacy Policy", value: "PRIVACY_POLICY"},
    {label: "Terms of Use", value: "TERMS_OF_USE"}
];
export const messageTypes = [
    {label: "OTP", value: "OTP"},
    {label: "Forgot Password", value: "FORGOT_PASSWORD"},
    {label: "Welcome Message", value: "WELCOME_USER"},
    {label: "Payment Received", value: "PAYMENT_RECEIVED"},
    {label: "News Letter Unsubscription", value: "NEWS_LETTER_UNSUBSCRIPTION"},
    {label: "News Letter New House Plan Added", value: "NEWS_LETTER_NEW_HOUSE_PLAN_ADDED"},
    {label: "News Letter Discount Added", value: "NEWS_LETTER_DISCOUNT_ADDED"},
    {label: "News Letter User Reminder", value: "NEWS_LETTER_USER_REMINDER"},
    {label: "Premium Account Subscription", value: "PREMIUM_ACCOUNT_SUBSCRIPTION"}
];

export const documentTypes = [
    {label: "CAD", value: "CAD"},
    {label: "PDF", value: "PDF"}
];

export const displayIcons = [
    {label: "Bedrooms", value: "bedroom"},
    {label: "Square Meters", value: "measure"},
    {label: "Stories", value: "stories"},
    {label: "Bathrooms", value: "bathrooms"}
];

export const spreadElements = {
    display: 'flex',
    //flexDirection: 'row-reverse',
    alignItems: 'center',
    float: 'right',
    marginTop: "2em"
};
