import {BaseApiClient} from "./BaseApiClient";
import {MaximumRecordsPerPage} from "../common/SystemConstants";
import {EndpointDeleteHousePlanDesignCategory, EndpointGetHousePlanDesignCategories, EndpointSaveHousePlanDesignCategory, EndpointSaveHousePlanDesignCategoryWithAttachment} from "../routes/Backend";
import {getBearerToken} from "../common/Utils";

export class HousePlanDesignCategoryService extends BaseApiClient {

    /**
     *
     * @param {*} jsonData
     * @returns
     */
    async saveRecord(jsonData) {
        return await fetch(EndpointSaveHousePlanDesignCategory, this.PostRequestDetails(jsonData));
    }

    async saveRecordWithJsonAndFile(formData, id, housePlanId, designCategoryId, pricePerSquareMeter, useAsDefault) {

        // Set up the request headers
        let headers = {Authorization: getBearerToken()};

        // Fetch options
        let options = {
            method: "POST",
            headers: headers,
            body: formData
        };

        let searchParameters = {};
        if (id != null)
            searchParameters.id = id;
        if (housePlanId != null)
            searchParameters.housePlanId = housePlanId;
        if (designCategoryId != null)
            searchParameters.designCategoryId = designCategoryId;
        searchParameters.pricePerSquareMeter = pricePerSquareMeter;
        searchParameters.useAsDefault = useAsDefault
        return await fetch(EndpointSaveHousePlanDesignCategoryWithAttachment + new URLSearchParams(searchParameters), options);
    }

    /**
     *
     * @param housePlanId
     * @param searchTerm
     * @param offset
     * @param limit
     * @returns {Promise<Response>}
     */
    async getRecords(housePlanId, searchTerm, offset, limit = MaximumRecordsPerPage) {
        let searchParameters = {offset: offset, limit: limit};
        if (searchTerm !== null) searchParameters.searchTerm = searchTerm;
        return await fetch(EndpointGetHousePlanDesignCategories.replace(":id", housePlanId) + new URLSearchParams(searchParameters), this.GetRequestDetails());
    }

    /**
     *
     * @param id
     * @returns {Promise<Response>}
     */
    async deleteRecord(id) {
        let data = {};
        console.log(id);
        return await fetch(EndpointDeleteHousePlanDesignCategory.replace(":id", id), this.PostRequestDetails(data));
    }

}
