// This contains general application css syles
export const ButtonCloseStyle = "p-button-rounded p-button-warning p-mr-2";
export const ButtonTrashStyle = "p-button-rounded p-button-danger p-mr-2";
export const ButtonDeleteStyle = "p-button-rounded p-button-danger p-mr-2";
export const ButtonSubmitStyle = "p-button-primary p-mr-2 p-mb-2";
export const ButtonAddNewStyle = "p-button-success p-mr-2 p-mb-2 p-ml-3";
export const ButtonAddNewStyleSecondary = "p-button-secondary p-mr-2 p-mb-2 p-ml-3";
export const BackButtonStyle = "p-button-outlined p-button-secondary p-mr-2 p-mb-2";
export const NextButtonStyle = "p-button-outlined p-mr-2 p-mb-2";
export const CompleteButtonStyle = "p-button-outlined p-button-success p-mr-2 p-mb-2";

export const formMarginTop = "0.5em";
export const LeftComponentMarginTop = "0.01em";
export const LeftComponentHeight = "68vh";
export const RightComponentHeight = "58vh";
export const BottomComponentHeight = "10vh";
export const RightComponentMarginTop = "1em";
export const BackgroundColor = "#f5f5f5";

export const FormErrorsColor = "red";
export const FormErrorsBackground = "white";
export const FormErrorsPadding = "5px";

export const RecordActiveBadge = "p-button-success p-button-text";
export const RecordErrorBadge = "p-button-danger p-button-text";
export const RecordHelpBadge = "p-button-help p-button-text";
export const RecordWarningBadge = "p-button-warning p-button-text";
export const SenderBackgroudColor = "#FFC107";
export const RecipientBackgroudColor = "#673AB7";
export const MinimumButtonSize = "150px";

export const socialMediaItem = "social-media-box";

export const styles = [];
