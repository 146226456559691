import {BaseApiClient} from "./BaseApiClient";
import {MaximumRecordsPerPage} from "../common/SystemConstants";
import {EndpointDeleteNewsLetter, EndpointGetNewsLetters, EndpointSaveNewsLetter} from "../routes/Backend";

export class NewsLetterService extends BaseApiClient {

    /**
     *
     * @param {*} jsonData
     * @returns
     */
    async saveRecord(jsonData) {
        return await fetch(EndpointSaveNewsLetter, this.PostRequestDetails(jsonData));
    }

    /**
     *
     * @param searchTerm
     * @param offset
     * @param limit
     * @returns {Promise<Response>}
     * @constructor
     */
    async getRecords(searchTerm, offset, limit = MaximumRecordsPerPage) {
        let searchParameters = {offset: offset, limit: limit};
        if (searchTerm !== null) searchParameters.searchTerm = searchTerm;
        return await fetch(EndpointGetNewsLetters + new URLSearchParams(searchParameters), this.GetRequestDetails());
    }

    /**
     *
     * @param id
     * @returns {Promise<Response>}
     */
    async deleteRecord(id) {
        let data = {};
        console.log(id);
        return await fetch(EndpointDeleteNewsLetter.replace(":id", id), this.PostRequestDetails(data));
    }

}
