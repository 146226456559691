import Dashboard from "../pages/home/Dashboard";
import {Login} from "../pages/Login";
import ViewInformationPages from "../pages/setups/ViewInformationPages";
import ViewSizes from "../pages/houseplans/ViewSizes";
import ViewBlogs from "../pages/setups/ViewBlogs";
import ViewStyles from "../pages/houseplans/ViewStyles";
import ViewFAQs from "../pages/setups/ViewFAQs";
import ViewDesignCategories from "../pages/houseplans/ViewDesignCategories";
import ViewUsers from "../pages/security/ViewUsers";
import ViewAccountTypes from "../pages/setups/ViewAccountTypes";
import ViewCostCalculatorItems from "../pages/calculator/ViewCostCalculatorItems";
import ViewCostCalculatorCategories from "../pages/calculator/ViewCostCalculatorCategories";
import ViewCostCalculator from "../pages/calculator/ViewCostCalculator";
import React from "react";
import ViewPayments from "../pages/payments/ViewPayments";
import ViewMessageTemplates from "../pages/setups/ViewMessageTemplates";
import ViewFeatures from "../pages/houseplans/ViewFeatures";
import ViewHousePlans from "../pages/houseplans/ViewHousePlans";
import ViewManageHousePlan from "../pages/houseplans/ViewManageHousePlan";
import ViewRoles from "../pages/security/ViewRoles";
import ViewPlotSizes from "../pages/houseplans/ViewPlotSizes";

export const LoginPath = "/";
export const UIHomePath = "/home";
export const Settings = "/settings/"
export const UIDocumentationPath = "/documentation";
export const UIEmptyPath = "/empty";
export const UIHelpPath = "/help";
export const UIPathInvoice = "/invoice";
export const UIPathTimeline = "/timeline";

export const UIPathHousePlans = "/house-plans";
export const UIPathManageHousePlan = "/house-plan/manage";
export const UIPathCostCalculatorItems = "/cost-calculator/items";
export const UIPathCostCalculatorCategories = "/cost-calculator/categories";
export const UIPathCostCalculator = "/cost-calculator";
export const UIPathPayments = "/payments";
export const UIPathNewsLetters = "/news-letters";
export const UIPathNewsLetterSubscribers = "/news-letter/subscribers";
export const UIPathMessageTemplates = "/message-templates";
export const UIPathUserAccounts = "/users";
export const UIPathRoles = "/roles";
export const UIPathAccountTypes = "/account-types";
export const UIPathBlogs = "/blogs";
export const UIPathDesignCategories = "/design-categories";
export const UIPathFAQs = "/faqs";
export const UIPathFeatures = "/features";
export const UIPathStyles = "/styles";
export const UIPathSizes = "/sizes";
export const UIPathPlotSizes = "/plot-sizes";
export const UIPathInformationPages = "/information-pages";
export const UIPathLandingPageImages = "/landing-page-images";

export const unAuthenticatedMenu = [
    {label: "Login", icon: "pi pi-fw pi-sign-in", to: Login},
];

export const authenticatedMenu = [
    {
        label: '',
        icon: 'pi pi-fw pi-compass',
        items: [
            {label: 'Dashboard', icon: 'pi pi-fw pi-th-large', to: UIHomePath},
            {
                label: 'House Plans',
                icon: 'pi pi-fw pi-id-card',
                items: [
                    {label: 'Design Categories', icon: 'pi pi-fw pi-align-left', to: UIPathDesignCategories},
                    {label: 'Styles', icon: 'pi pi-fw pi-align-left', to: UIPathStyles},
                    {label: 'Sizes', icon: 'pi pi-fw pi-align-left', to: UIPathSizes},
                    {label: 'Plot Sizes', icon: 'pi pi-fw pi-align-left', to: UIPathPlotSizes},
                    {label: 'Features', icon: 'pi pi-fw pi-align-left', to: UIPathFeatures},
                    {label: 'House Plans', icon: 'pi pi-fw pi-id-card', to: UIPathHousePlans},
                ]
            },
            {
                label: 'Cost Calculator',
                icon: 'pi pi-fw pi-book',
                items: [
                    {label: 'Cost Calculator Items', icon: 'pi pi-fw pi-align-left', to: UIPathCostCalculatorItems},
                    {label: 'Cost Calculator Categories', icon: 'pi pi-fw pi-align-left', to: UIPathCostCalculatorCategories},
                    {label: 'Cost Calculator', icon: 'pi pi-fw pi-align-left', to: UIPathCostCalculator},
                ]
            },
            {label: 'Payments', icon: 'pi pi-fw pi-bookmark', to: UIPathPayments},
            {
                label: 'News Letters',
                icon: 'pi pi-fw pi-exclamation-circle',
                items: [
                    {label: 'News Letters', icon: 'pi pi-fw pi-align-left', to: UIPathNewsLetters},
                    {label: 'News Letters Subscribers', icon: 'pi pi-fw pi-align-left', to: UIPathNewsLetterSubscribers},
                ]
            },
            {
                label: 'Security',
                icon: 'pi pi-fw pi-lock',
                items: [
                    {label: 'Roles & Permissions', icon: 'pi pi-fw pi-align-left', to: UIPathRoles},
                    {label: 'Users', icon: 'pi pi-fw pi-align-left', to: UIPathUserAccounts},
                ]
            },
            {
                label: 'Settings',
                icon: 'pi pi-fw pi-cog',
                items: [
                    {label: 'Account Types', icon: 'pi pi-fw pi-align-left', to: UIPathAccountTypes},
                    {label: 'Blogs', icon: 'pi pi-fw pi-align-left', to: UIPathBlogs},
                    {label: 'FAQs', icon: 'pi pi-fw pi-align-left', to: UIPathFAQs},
                    {label: 'Message Templates', icon: 'pi pi-fw pi-align-left', to: UIPathMessageTemplates},
                    {label: 'Information Pages', icon: 'pi pi-fw pi-align-left', to: UIPathInformationPages},
                    {label: 'Landing Page Images', icon: 'pi pi-fw pi-align-left', to: UIPathLandingPageImages},
                ]
            },
        ]
    },
];
export const breadcrumbControls = [
    {path: UIHomePath, parent: '', label: 'Dashboard'},
    {path: UIPathSizes, parent: '', label: 'Sizes'},
    {path: UIPathPlotSizes, parent: '', label: 'Plot Sizes'},
    {path: UIPathBlogs, parent: '', label: 'Blogs'},
    {path: UIPathFAQs, parent: '', label: 'FAQs'},
    {path: UIPathInformationPages, parent: '', label: 'Information Pages'},
    {path: UIPathStyles, parent: '', label: 'Styles'},
    {path: UIPathFeatures, parent: '', label: 'Features'},
    {path: UIPathCostCalculatorCategories, parent: '', label: 'Cost Calculator Categories'},
    {path: UIPathCostCalculator, parent: '', label: 'Cost Calculator'},
    {path: UIPathCostCalculatorItems, parent: '', label: 'Cost Calculator Items'},
    {path: UIPathAccountTypes, parent: '', label: 'Account Types'},
    {path: UIPathDesignCategories, parent: '', label: 'Design Categories'},
    {path: UIPathPayments, parent: '', label: 'Payment Transactions'},
    {path: UIPathMessageTemplates, parent: '', label: 'Message Templates'},
    {path: UIPathHousePlans, parent: '', label: 'House Plans'},
    {path: UIPathNewsLetters, parent: '', label: 'News Letters'},
    {path: UIPathNewsLetterSubscribers, parent: '', label: 'News Letters Subscribers'},
    {path: UIPathManageHousePlan, parent: 'House Plans', label: 'Manage House Plan'},
    {path: UIPathRoles, parent: 'Security', label: 'Roles & Permissions'},
    {path: UIPathUserAccounts, parent: 'Security', label: 'User Accounts'},
    {path: UIPathLandingPageImages, parent: 'Settings', label: 'Landing Page Images'},
];

export const unAuthenticatedRouters = [
    {path: LoginPath, label: "Login", component: Login},

];

export const authenticatedRouters = [
    {path: UIHomePath, label: "Dashboard", component: Dashboard, exact: true},
    {path: UIPathInformationPages, label: "Information Pages", component: ViewInformationPages, exact: true},
    {path: UIPathSizes, label: "Sizes", component: ViewSizes, exact: true},
    {path: UIPathPlotSizes, label: "Plot Sizes", component: ViewPlotSizes, exact: true},
    {path: UIPathBlogs, label: "Blogs", component: ViewBlogs, exact: true},
    {path: UIPathStyles, label: "Styles", component: ViewStyles, exact: true},
    {path: UIPathFAQs, label: "FAQs", component: ViewFAQs, exact: true},
    {path: UIPathDesignCategories, label: "Design Categories", component: ViewDesignCategories, exact: true},
    {path: UIPathUserAccounts, label: "Users", component: ViewUsers, exact: true},
    {path: UIPathRoles, label: "Users", component: ViewRoles, exact: true},
    {path: UIPathAccountTypes, label: "Account Types", component: ViewAccountTypes, exact: true},
    {path: UIPathCostCalculatorItems, label: "Cost Calculator Items", component: ViewCostCalculatorItems, exact: true},
    {path: UIPathCostCalculatorCategories, label: "Cost Calculator Categories", component: ViewCostCalculatorCategories, exact: true},
    {path: UIPathCostCalculator, label: "Cost Calculator", component: ViewCostCalculator, exact: true},
    {path: UIPathPayments, label: "Payments", component: ViewPayments, exact: true},
    {path: UIPathFeatures, label: "Features", component: ViewFeatures, exact: true},
    {path: UIPathHousePlans, label: "House Plans", component: ViewHousePlans, exact: true},
    {path: UIPathManageHousePlan, label: "Manage House Plan", component: ViewManageHousePlan, exact: true},
    {path: UIPathMessageTemplates, label: "Message Templates", component: ViewMessageTemplates, exact: true},
];
