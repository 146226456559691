import React from "react";
import { getBearerToken } from "../common/Utils";

export class BaseApiClient extends React.Component {

  /**
   * This method is used to compose request details to be sent to the server
   * @param {*} requestBody
   * @returns
   */
  PostRequestDetails(requestBody) {
    let headers = {
      "Content-Type": "application/json",
      Authorization: getBearerToken(),
    };
    let data = {
      method: "POST",
      headers: headers,
      body: requestBody !== null ? JSON.stringify(requestBody) : "",
    };
    return data;
  }

    PostFormDate(requestBody) {
        let headers = {
            "Content-Type": "application/json",
            Authorization: getBearerToken(),
        };
        let data = {
            method: "POST",
            headers: headers,
            body: requestBody ,
        };
        return data;
    }

  PublicPostRequestDetails(requestBody) {
    let headers = {
      "Content-Type": "application/json",
    };
    let data = {
      method: "POST",
      headers: headers,
      body: requestBody !== null ? JSON.stringify(requestBody) : "",
    };
    return data;
  }

  /**
   * This method is used to compose GET request details to be sent to the server.
   *
   * @returns
   */
  GetRequestDetails() {
    let headers = {
      "Content-Type": "application/json",
      Authorization: getBearerToken()
    };

    let data = {
      method: "GET",
      headers: headers,
    };
    return data;
  }
}
