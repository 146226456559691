import React, {useEffect, useRef, useState} from 'react';
import {classNames} from 'primereact/utils';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppBreadcrumb from './AppBreadcrumb';
import AppInlineProfile from './AppInlineProfile';
import {Tooltip} from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import {
    authenticatedMenu,
    authenticatedRouters,
    breadcrumbControls,
    UIHomePath,
    UIPathAccountTypes,
    UIPathBlogs,
    UIPathCostCalculator,
    UIPathCostCalculatorCategories,
    UIPathCostCalculatorItems,
    UIPathDesignCategories,
    UIPathFAQs, UIPathFeatures, UIPathHousePlans,
    UIPathInformationPages, UIPathLandingPageImages, UIPathManageHousePlan, UIPathMessageTemplates, UIPathNewsLetters, UIPathNewsLetterSubscribers, UIPathPayments, UIPathPlotSizes, UIPathRoles,
    UIPathSizes,
    UIPathStyles,
    UIPathUserAccounts,
    unAuthenticatedMenu,
    unAuthenticatedRouters
} from './routes/Frontend';
import {isLoggedIn} from './common/Utils';
import Dashboard from "./pages/home/Dashboard";
import ViewInformationPages from "./pages/setups/ViewInformationPages";
import ViewSizes from "./pages/houseplans/ViewSizes";
import ViewFAQs from "./pages/setups/ViewFAQs";
import ViewBlogs from "./pages/setups/ViewBlogs";
import ViewStyles from "./pages/houseplans/ViewStyles";
import ViewDesignCategories from "./pages/houseplans/ViewDesignCategories";
import ViewAccountTypes from "./pages/setups/ViewAccountTypes";
import ViewUsers from "./pages/security/ViewUsers";
import ViewCostCalculatorItems from "./pages/calculator/ViewCostCalculatorItems";
import ViewCostCalculatorCategories from "./pages/calculator/ViewCostCalculatorCategories";
import ViewCostCalculator from "./pages/calculator/ViewCostCalculator";
import ViewPayments from "./pages/payments/ViewPayments";
import ViewMessageTemplates from "./pages/setups/ViewMessageTemplates";
import ViewFeatures from "./pages/houseplans/ViewFeatures";
import ViewHousePlans from "./pages/houseplans/ViewHousePlans";
import {DefaultColor} from "./common/SystemConstants";
import ViewManageHousePlan from "./pages/houseplans/ViewManageHousePlan";
import ViewRoles from "./pages/security/ViewRoles";
import ViewLandingPageImages from "./pages/setups/ViewLandPageImages";
import ViewNewsLetterSubscribers from "./pages/newsletters/ViewNewsLetterSubscribers";
import ViewNewsLetters from "./pages/newsletters/ViewNewsLetters";
import ViewPlotSizes from './pages/houseplans/ViewPlotSizes';

const App = () => {
    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, setMenuMode] = useState('static');
    const [darkMenu, setDarkMenu] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [inlineMenuActive, setInlineMenuActive] = useState(false);
    const [profileMode, setProfileMode] = useState('popup');
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();

    const navigate = useNavigate();
    const isUserLoggedIn = isLoggedIn();

    const menu = isLoggedIn() ? authenticatedMenu : unAuthenticatedMenu;
    const routers = isLoggedIn() ? authenticatedRouters : unAuthenticatedRouters;

    let menuClick = false;
    let configClick = false;
    let topbarItemClick = false;
    let inlineMenuClick = false;

    const breadcrumb = breadcrumbControls;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    let meta = breadcrumb.find((obj) => {
        return obj.path === location.pathname;
    });


    const onDocumentClick = () => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }
            hideOverlayMenu();
        }

        if (!inlineMenuClick && profileMode === 'inline' && isSlim() && !isMobile()) {
            setInlineMenuActive(false);
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        inlineMenuClick = false;
        configClick = false;
        topbarItemClick = false;
        menuClick = false;
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive((prevMenuActive) => !prevMenuActive);
    };

    const onMenuClick = () => {
        menuClick = true;

        if (inlineMenuActive && !inlineMenuClick) {
            setInlineMenuActive(false);
        }
    };

    const isMenuVisible = () => {
        if (isDesktop()) {
            if (menuMode === 'static') return !staticMenuDesktopInactive;
            else if (menuMode === 'overlay') return overlayMenuActive;
            else return true;
        } else {
            return true;
        }
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarMenuActive(false);

        if (isOverlay() && !isMobile()) {
            setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
        } else {
            if (isDesktop()) {
                setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
            } else {
                setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
            }
        }

        event.preventDefault();
    };

    const onProfileButtonClick = (event) => {
        setInlineMenuActive((prevInlineMenuActive) => !prevInlineMenuActive);
        inlineMenuClick = true;

        if (isSlim() || isHorizontal()) {
            setMenuActive(false);
        }
    };

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevTopbarMenuActive) => !prevTopbarMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarItemClick = (event, item) => {
        topbarItemClick = true;

        if (activeTopbarItem === item) {
            setActiveTopbarItem(null);
        } else {
            setActiveTopbarItem(item);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const isDesktop = () => {
        return window.innerWidth > 896;
    };

    const isMobile = () => {
        return window.innerWidth <= 896;
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isStatic = () => {
        return menuMode === 'static';
    };

    const hasInlineProfile = profileMode === 'inline' && !isHorizontal();

    const containerClassName = classNames('layout-wrapper', {
        'layout-static': isStatic(),
        'layout-overlay': isOverlay(),
        'layout-overlay-active': overlayMenuActive,
        'layout-horizontal': isHorizontal(),
        'layout-slim': isSlim(),
        'layout-static-inactive': staticMenuDesktopInactive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-menu-dark': darkMenu,
        'layout-menu-light': !darkMenu,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple
    });

    const menuContainerClassName = classNames('layout-menu-container', {'layout-menu-container-inactive': !isMenuVisible()});

    return (
        <div className={containerClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus"/>

            <AppTopbar
                topbarMenuActive={topbarMenuActive}
                activeTopbarItem={activeTopbarItem}
                onMenuButtonClick={onMenuButtonClick}
                onTopbarMenuButtonClick={onTopbarMenuButtonClick}
                onTopbarItemClick={onTopbarItemClick}
                isHorizontal={isHorizontal()}
                profileMode={profileMode}
                isMobile={isMobile}
            />

            <div className={menuContainerClassName} onClick={onMenuClick}>
                <div className="layout-menu-logo" style={{background: DefaultColor}}>
                    <button className="p-link" onClick={() => navigate(UIHomePath)}>
                        <img id="layout-menu-logo" src="assets/layout/images/logo-white.png" library="babylon-layout" alt="babylon-logo"/>
                    </button>
                </div>
                <div className="layout-menu-wrapper">
                    <div className="menu-scroll-content">
                        {hasInlineProfile && <AppInlineProfile inlineMenuActive={inlineMenuActive} onProfileButtonClick={onProfileButtonClick}/>}
                        <AppMenu model={menu} menuMode={menuMode} active={menuActive} onMenuitemClick={onMenuitemClick} onRootMenuitemClick={onRootMenuitemClick}/>
                    </div>
                </div>
            </div>

            <div className="layout-main">
                {isUserLoggedIn && <AppBreadcrumb routers={routers} meta={meta}/>}

                <div className="layout-content">
                    <Routes>
                        <Route path={UIHomePath} element={<Dashboard/>}/>
                        <Route path={UIPathInformationPages} element={<ViewInformationPages/>}/>
                        <Route path={UIPathSizes} element={<ViewSizes/>}/>
                        <Route path={UIPathPlotSizes} element={<ViewPlotSizes/>}/>
                        <Route path={UIPathBlogs} element={<ViewBlogs/>}/>
                        <Route path={UIPathStyles} element={<ViewStyles/>}/>
                        <Route path={UIPathFAQs} element={<ViewFAQs/>}/>
                        <Route path={UIPathDesignCategories} element={<ViewDesignCategories/>}/>
                        <Route path={UIPathUserAccounts} element={<ViewUsers/>}/>
                        <Route path={UIPathRoles} element={<ViewRoles/>}/>
                        <Route path={UIPathAccountTypes} element={<ViewAccountTypes/>}/>
                        <Route path={UIPathCostCalculatorItems} element={<ViewCostCalculatorItems/>}/>
                        <Route path={UIPathCostCalculatorCategories} element={<ViewCostCalculatorCategories/>}/>
                        <Route path={UIPathCostCalculator} element={<ViewCostCalculator/>}/>
                        <Route path={UIPathPayments} element={<ViewPayments/>}/>
                        <Route path={UIPathMessageTemplates} element={<ViewMessageTemplates/>}/>
                        <Route path={UIPathFeatures} element={<ViewFeatures/>}/>
                        <Route path={UIPathHousePlans} element={<ViewHousePlans/>}/>
                        <Route path={UIPathManageHousePlan} element={<ViewManageHousePlan/>}/>
                        <Route path={UIPathLandingPageImages} element={<ViewLandingPageImages/>}/>
                        <Route path={UIPathNewsLetters} element={<ViewNewsLetters/>}/>
                        <Route path={UIPathNewsLetterSubscribers} element={<ViewNewsLetterSubscribers/>}/>
                        {/*} />
                        <Route path={UIPathNewsLetters} element={<ChartDemo />} />*/}

                    </Routes>
                </div>

                <AppFooter/>
            </div>

            {staticMenuMobileActive && <div className="layout-mask"></div>}
        </div>
    );
};

export default App;
