
const MaximumErrorMessageDisplayTimeInSeconds = 8000;
export const SuccessMessageTimeInSeconds = 3000

export const showSuccessMessage = (context_ref, message) => {
    if (context_ref  && context_ref.current)
        context_ref.current.show({ severity: 'success', content: message, life: MaximumErrorMessageDisplayTimeInSeconds});
};

export const showInfoMessage = (context_ref, message) => {
    if (context_ref  && context_ref.current)
        context_ref.current.show({ severity: 'info', content: message, life: MaximumErrorMessageDisplayTimeInSeconds });
};

export const showWarnMessage = (context_ref, message) => {
    if (context_ref  && context_ref.current)
        context_ref.current.show({ severity: 'warn', content: message, life: MaximumErrorMessageDisplayTimeInSeconds });
};

export const showErrorMessage = (context_ref, message) => {
    if (context_ref  && context_ref.current)
        context_ref.current.show({ severity: 'error', content: message, life: MaximumErrorMessageDisplayTimeInSeconds });
};


/**
 * This method is used to display an error message received from the server.
 * 
 * @param {*} httpStatusCode 
 * @param {*} responseJson 
 * @param {*} context_ref 
 */
export function showServerError(httpStatusCode, responseJson, context_ref) {
    console.log(context_ref)
    if (responseJson && context_ref && context_ref.current) {
        showErrorMessage(context_ref, responseJson.message);
    }
    
}

/**
 * 
 * @param {*} context_ref 
 * @param {*} errorMessage 
 */
export function showError(context_ref, errorMessage) {
    showErrorMessage(context_ref, errorMessage);    
}