import {BaseApiClient} from "./BaseApiClient";
import {MaximumRecordsPerPage} from "../common/SystemConstants";
import {EndpointGetWalletTopUpTransactions} from "../routes/Backend";

export class PaymentService extends BaseApiClient {

    /**
     *
     * @param searchTerm
     * @param offset
     * @param limit
     * @returns {Promise<Response>}
     * @constructor
     */
    async getRecords(searchTerm, offset, limit = MaximumRecordsPerPage) {
        let searchParameters = {offset: offset, limit: limit};
        if (searchTerm !== null) searchParameters.searchTerm = searchTerm;
        return await fetch(EndpointGetWalletTopUpTransactions + new URLSearchParams(searchParameters), this.GetRequestDetails());
    }

}
