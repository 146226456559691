import {BaseApiClient} from "./BaseApiClient";
import {MaximumRecordsPerPage} from "../common/SystemConstants";
import {EndpointApproveHousePlan, EndpointDeleteHousePlan, EndpointGetHousePlans, EndpointPullDownHousePlan, EndpointSaveHousePlan} from "../routes/Backend";

export class HousePlanService extends BaseApiClient {

    /**
     *
     * @param {*} jsonData
     * @returns
     */
    async saveRecord(jsonData) {
        return await fetch(EndpointSaveHousePlan, this.PostRequestDetails(jsonData));
    }

    /**
     *
     * @param searchTerm
     * @param offset
     * @param limit
     * @returns {Promise<Response>}
     * @constructor
     */
    async getRecords(searchTerm, offset, limit = MaximumRecordsPerPage) {
        let searchParameters = {offset: offset, limit: limit};
        if (searchTerm !== null) searchParameters.searchTerm = searchTerm;
        return await fetch(EndpointGetHousePlans + new URLSearchParams(searchParameters), this.GetRequestDetails());
    }

    /**
     *
     * @param id
     * @returns {Promise<Response>}
     */
    async deleteRecord(id) {
        let data = {};
        console.log(id);
        return await fetch(EndpointDeleteHousePlan.replace(":id", id), this.PostRequestDetails(data));
    }

    async approveHousePlan(id) {
        let data = {};
        console.log(id);
        return await fetch(EndpointApproveHousePlan.replace(":id", id), this.PostRequestDetails(data));
    }

    async pullDownHousePlan(id) {
        let data = {};
        console.log(id);
        return await fetch(EndpointPullDownHousePlan.replace(":id", id), this.PostRequestDetails(data));
    }
}
