import React, {useEffect, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {DefaultErrorMessage, MaximumRecordsPerPage} from "../../common/SystemConstants";
import {showServerError} from "../../common/MessageDialog";
import {Messages} from "primereact/messages";
import {DataView} from "primereact/dataview";
import {FileUpload} from "primereact/fileupload";
import {EndpointGetFileBytes, EndpointLandingPageImage} from "../../routes/Backend";
import {FileService} from "../../controllers/FileService";


const ViewLandingPageImages = () => {
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const message = useRef();
    const [searchValue, setSearchValue] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(MaximumRecordsPerPage);

    // Drawings
    const [housePlanDrawings, setHousePlanDrawings] = useState(null);
    const [housePlanDrawing, setHousePlanDrawing] = useState(null);
    const [housePlanDrawingId, setHousePlanDrawingId] = useState(null);
    const [deleteHousePlanDrawingDialog, setDeleteHousePlanDrawingDialog] = useState(false);
    const [layout, setLayout] = useState('grid');
    const uploadImageUrl = EndpointLandingPageImage;

    const fetchRecordsFromServer = () => {
        setIsLoading(true);
        new FileService().getLandingPageImages(null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setHousePlanDrawings(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
            console.log(error);
        });
    };


    const hideDeleteHousePlanDrawingDialog = () => {
        setDeleteHousePlanDrawingDialog(false);
    };

    const confirmDeleteHousePlanDrawing = (record) => {
        setHousePlanDrawingId(record.id);
        setHousePlanDrawing(record);
        setDeleteHousePlanDrawingDialog(true);
    };

    const deleteHousePlanDrawing = () => {
        setIsLoading(true);
        new FileService().deleteRecord(housePlanDrawingId)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        hideDeleteHousePlanDrawingDialog();
                        fetchRecordsFromServer();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
            console.log(error);
        });
    };

    const deleteHousePlanDrawingDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteHousePlanDrawingDialog}/>
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteHousePlanDrawing}/>
        </>
    );


    const itemTemplate = (product, layout, index) => {
        if (!product) {
            return;
        }
        if (layout === 'list') return gridItem(product);
        else if (layout === 'grid') return gridItem(product);
    };

    const gridItem = (item) => {
        const imageUrl = `${EndpointGetFileBytes}`.replace(":id", item.id);
        return (
            <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2" key={item.id}>
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-column align-items-center gap-3" style={{padding: "0.5rem;"}}> {/* Reduced padding here */}
                        <img className="w-9 shadow-2 border-round"
                             src={imageUrl}
                             alt={item.name}/>
                    </div>
                    <div className="flex align-items-center justify-content-between">
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning mt-2 ml-auto"
                            onClick={() => confirmDeleteHousePlanDrawing(item)}></Button>
                    </div>
                </div>
            </div>
        );
    };

    function handleUpload(event) {
        for (let file of event.files) {
            const formData = new FormData();
            formData.append('file', file);
            setIsLoading(true);
            new FileService().saveLandingPageImage(formData)
                .then(async (response) => {
                    setIsLoading(false);
                    response.json().then((value) => {
                        if (response.ok) {
                            fetchRecordsFromServer();
                        } else {
                            showServerError(response.status, value ? value : DefaultErrorMessage, message);
                        }
                    });
                }).catch((error) => {
                console.log(error);
            });
        }

    };


    useEffect(() => {
        fetchRecordsFromServer();
    }, []);

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <div className="grid p-fluid">
                        <Messages ref={message}/>

                        <div className="col-12 md:col-12">
                            <FileUpload style={{float: "right", width: "100%"}} className="p-button-success"
                                        multiple
                                        name="file"
                                        url={uploadImageUrl}
                                        accept="image/*"
                                        maxFileSize={1000000}
                                        chooseLabel="Browse Landing Image"
                                        onUpload={handleUpload}
                                        emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>}/>
                        </div>

                        <hr/>
                        <div className="col-12 md:col-12">
                            <DataView
                                value={housePlanDrawings}
                                itemTemplate={itemTemplate}
                                layout={layout}/>

                            <Dialog visible={deleteHousePlanDrawingDialog} style={{width: '450px'}} header="Confirm" modal footer={deleteHousePlanDrawingDialogFooter} onHide={hideDeleteHousePlanDrawingDialog}>
                                <div className="flex align-items-center justify-content-center">
                                    <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                                    {housePlanDrawing && (
                                        <span>Are you sure you want to delete this record?</span>
                                    )}
                                </div>
                            </Dialog></div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
};

export default ViewLandingPageImages;
