import { BaseApiClient } from "./BaseApiClient";
import { MaximumRecordsPerPage } from "../common/SystemConstants";
import { EndpointDeleteFile, EndpointSaveFile, EndpointGetFiles, EndpointLandingPageImage, EndpointGetLandingPageImages, EndpointGetDocuments, EndpointUploadDocument, EndpointUpdateFile } from "../routes/Backend";
import { getBearerToken } from "../common/Utils";

export class FileService extends BaseApiClient {

    /**
     *
     * @param {*} jsonData
     * @returns
     */
    async saveRecord(formData, housePlanId, blurred) {
        let headers = {
            Authorization: getBearerToken(),
        };
        let data = {
            method: "POST",
            headers: headers,
            body: formData,
        };
        let searchParameters = { blurred: blurred };
        return await fetch(EndpointSaveFile.replace(":id", housePlanId) + new URLSearchParams(searchParameters), data);
    }

    async saveLandingPageImage(formData) {
        let headers = {
            Authorization: getBearerToken(),
        };
        let data = {
            method: "POST",
            headers: headers,
            body: formData,
        };
        return await fetch(EndpointLandingPageImage, data);
    }

    /**
     *
     * @param housePlanId
     * @param searchTerm
     * @param offset
     * @param limit
     * @returns {Promise<Response>}
     */
    async getRecords(housePlanId, searchTerm, offset, limit = MaximumRecordsPerPage) {
        let searchParameters = { offset: offset, limit: limit };
        if (searchTerm !== null) searchParameters.searchTerm = searchTerm;
        if (housePlanId !== null) searchParameters.recordId = housePlanId;
        return await fetch(EndpointGetFiles + new URLSearchParams(searchParameters), this.GetRequestDetails());
    }

    async uploadDocument(formData, housePlanId, documentType) {
        let headers = {
            Authorization: getBearerToken(),
        };
        let data = {
            method: "POST",
            headers: headers,
            body: formData,
        };
        return await fetch(EndpointUploadDocument.replace(":id", housePlanId).replace(":documentType", documentType), data);
    }

    async getDocuments(housePlanId, searchTerm, offset, limit = MaximumRecordsPerPage) {
        let searchParameters = { offset: offset, limit: limit };
        if (searchTerm !== null) searchParameters.searchTerm = searchTerm;
        if (housePlanId !== null) searchParameters.recordId = housePlanId;
        return await fetch(EndpointGetDocuments + new URLSearchParams(searchParameters), this.GetRequestDetails());
    }

    /**
     *
     * @param searchTerm
     * @param offset
     * @param limit
     * @returns {Promise<Response>}
     */
    async getLandingPageImages(searchTerm, offset, limit = MaximumRecordsPerPage) {
        let searchParameters = { offset: offset, limit: limit };
        if (searchTerm !== null) searchParameters.searchTerm = searchTerm;
        return await fetch(EndpointGetLandingPageImages + new URLSearchParams(searchParameters), this.GetRequestDetails());
    }

    /**
     *
     * @param id
     * @returns {Promise<Response>}
     */
    async deleteRecord(id) {
        let data = {};
        console.log(id);
        return await fetch(EndpointDeleteFile.replace(":id", id), this.PostRequestDetails(data));
    }

    /**
     *
     * @param id
     * @returns {Promise<Response>}
     */
    async updateBlurredRecord(id) {
        let data = {};
        console.log(id);
        return await fetch(EndpointUpdateFile.replace(":id", id), this.PostRequestDetails(data));
    }


}
