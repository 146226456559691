import React, { useContext,useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { DefaultErrorMessage, KeyBearerToken, KeyUserDetails } from '../common/SystemConstants';
import { UIHomePath } from '../routes/Frontend';
import { UserService } from '../controllers/UserService';
import { showServerError } from '../common/MessageDialog';
import { toJSONString } from '../common/Utils';
import { Messages } from 'primereact/messages';


export const Login = () => {
    const message = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

      //START OF THE SIGNIN function
      function signInHandler(data, event) {
        event.preventDefault();
        setIsLoading(true);

        new UserService()
          .Login(username, password)
          .then(async (response) => {
            setIsLoading(false);
            let bearerToken = response.headers.get("authorization");

            response.json().then((value) => {
              if (response.ok) {
                localStorage.setItem(KeyBearerToken, bearerToken);
                localStorage.setItem(KeyUserDetails, toJSONString(value));
                navigate(UIHomePath);
              } else {
                let error = value ? value : DefaultErrorMessage;
                showServerError(response.status, error, message);
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }


    return (
        <div className="login-body">
            <div className="login-panel"></div>

            <div className="login-content">
                <img src="assets/layout/images/logo-black.png" alt="babylon-layout" style={{height: "95px", width:"110px", marginBottom:"40px"}} />
                <h1>
                    <span>ALNAC</span> STAFF LOGIN
                </h1>
                <p>Welcome, please use the form to sign-in.</p>

               <form onSubmit={handleSubmit(signInHandler)} style={{ width: "100%" }}>

                   <div className="login-input-wrapper">
                       <Messages ref={message} />
                   </div>
                    <div className="login-input-wrapper">
                        <InputText placeholder="Username"
                            type="text"
                            id="inputgroup"
                            value={username}
                            {...register("username", {
                                required: " Enter username",
                                onChange: (e) => {
                                setUsername(e.target.value);
                                },
                            })}
                            className={`${ errors.username ? "p-inputtext p-component p-invalid" : "" }`}
                            />

                        <i className="pi pi-user"></i>
                    </div>

                    <div className="login-input-wrapper">
                        <InputText placeholder="Password"
                            type="password"
                            id="inputgroup"
                            value={password}
                            {...register("password", {
                                required: " Enter a password",
                                onChange: (e) => {
                                setPassword(e.target.value);
                                },
                            })}
                            className={`${ errors.password ? "p-inputtext p-component p-invalid" : "" }`}
                            />
                        <i className="pi pi-lock"></i>
                    </div>

                {!isLoading && (<Button label="Sign In" className="p-mr-2 p-mb-2"/> )}
                {isLoading && (
                    <Button label=" Logging in please wait..." icon="pi pi-bookmark" className="p-mr-2 p-mb-2" disabled>
                      <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    </Button>
                )}
                </form>
            </div>
        </div>
    );
};
